<template>
    <div class="accounts">
        <AccountsOptions v-if="!disableOptions" />
        <AccountsList />
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import { useAccountsStore } from '@/stores/accounts';
    import { useAccountStore } from '@/stores/account';
    import i18nViewMixin from '@/mixins/i18nViewMixin';
    import AccountsList from '@/components/accounts/AccountsList';
    import AccountsOptions from '@/components/accounts/AccountsOptions'

    export default {
        name: 'AccountsView',
        mixins: [i18nViewMixin],
        data() {
            return {
                seo_title: 'views.accounts.seo_title'
            };
        },
        components: {
            AccountsList,
            AccountsOptions
        },
        computed: {
            ...mapState(useAccountsStore, ['updatingOptions', 'found', 'options']),
            accountStore() {
                return useAccountStore();
            },
            disableOptions() {
                return this.found <= 10 && this.updatingOptions === false && this.options.search === '' && this.options.product === '';
            }
        },
        created() {
            this.accountStore.resetAccount();
        }
    }
</script>