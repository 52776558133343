<template>
    <div class="row user-item" :class="[user.editable && !user.self ? 'hasActions' : '']">
        <div class="item-image">
            <GravatarImage 
                :email="user.email ? user.email : null"
                :name="user.name ? user.name : user.email ? user.email : null"
            />
        </div>

        <div class="content">
            <div class="name">{{ user.email }}</div>
            <p class="description">
                {{ $t(`fields.user_role.options.${this.user.role}`) }}
            </p>
        </div>

        <div class="actions" v-if="user.editable && !user.self">
            <div class="btn-1 btn-s-icon" @click="editUserPopup">
                <div class="icon-edit-neg icon-xxs"></div>
            </div>
            <div class="btn-6 btn-s-icon" @click="deleteUserPopup">
                <div class="icon-trash-neg icon-xxs"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { markRaw } from 'vue';
import i18n from '@/plugins/i18n';
import { useUsersStore } from '@/stores/users';
import { usePopupStore } from '@/stores/popup';
import { useAccountStore } from '@/stores/account';
import { useToastStore } from '@/stores/toast';
import GravatarImage from '@/components/elements/misc/GravatarImage';
import UserEditForm from '@/components/forms/UserEditForm';

export default {
  name: 'UsersListItem',
  components: {
      GravatarImage
  },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  computed: {
    usersStore() {
        return useUsersStore();
    },
    accountStore() {
        return useAccountStore();
    },
    popupStore() {
        return usePopupStore();
    },
    toastStore() {
        return useToastStore();
    }
  },
  methods: {
    editUserPopup() {
        this.popupStore.showPopup('form', {
            title: 'popups.edit_user.title',
            description: 'popups.edit_user.description',
            formComponent: markRaw(UserEditForm),
            formProps: {
                user: this.user,
                account_id: this.accountStore.account && this.accountStore.account.account_id ? this.accountStore.account.account_id : null
            },
            translationPlaceholders: {
                description: {
                    userName: this.user.name ? this.user.name : this.user.email,
                    accountName: this.accountStore.account && this.accountStore.account.name ? this.accountStore.account.name : this.accountStore.account && this.accountStore.account.domain ? this.accountStore.account.domain : null
                }
            },
            onFormSuccess: () => {
                this.toastStore.addToast(i18n.global.t('toasts.success.user_updated', { nameOrEmail: this.user.name ? this.user.name : this.user.email }), 'success');
            },
        });
    },
    deleteUserPopup() {
        this.popupStore.showPopup('confirm', {
            title: 'popups.confirm_user_delete.title',
            description: 'popups.confirm_user_delete.description',
            btnConfirm: "popups.confirm_user_delete.button_confirm",
            btnCancel: "common.cancel_1",
            danger: true,
            confirmFunction: this.deleteUser,
            translationPlaceholders: {
                description: {
                    userName: this.user.name ? this.user.name : this.user.email,
                    accountName: this.accountStore.account && this.accountStore.account.name ? this.accountStore.account.name : this.accountStore.account && this.accountStore.account.domain ? this.accountStore.account.domain : null
                }
            }
        });
    },
    async deleteUser() {
        let user = this.accountStore.account && this.accountStore.account.account_id ? { account_id: this.accountStore.account.account_id, ...this.user } : this.user;
        
        try {
            const response = await this.usersStore.deleteUser(user);

            if(response) {
                this.toastStore.addToast(i18n.global.t('toasts.success.user_deleted', {email: user.email}), 'success');
            } else {
                this.toastStore.addToast(i18n.global.t('toasts.error.user_deletion_failed', {email: user.email}), 'error');
            } 
        } catch {
            this.toastStore.addToast(i18n.global.t('toasts.error.user_deletion_failed', {email: user.email}), 'error');   
        }
    }
  }
}
</script>