<template>
    <div class="form-type-0">
        <form @submit.prevent="createAccount" @input="onInputChange" ref="form" novalidate>
            <CustomInput
                v-model="name"
                inputKey="name"
                type="text"
                ref="name"
                autocomplete="organization"
                required
            />
            <CustomInput
                v-model="url"
                inputKey="url"
                type="url"
                ref="url"
                autocomplete="url"
                required
            />
            <button type="submit" :disabled="loading">
                <span v-if="loading" class="loading-spinner-type-1"></span>
                <span v-else>{{ $t('forms.create_account.button') }}</span>
            </button>
            <p v-if="formError" class="form-error">{{ $t('forms.create_account.error') }}</p>
        </form>
    </div>
</template>

<script>
    import { useAccountsStore } from '@/stores/accounts';
    import formMixin from '@/mixins/formMixin';
    import CustomInput from '@/components/elements/inputs/CustomInput';

    export default {
        mixins: [formMixin],
        emits: ['form_submission_successful'],
        components: {
            CustomInput
        },
        data() {
            return {
                name: '',
                url: 'https://'
            };
        },
        computed: {
            accountsStore() {
                return useAccountsStore();
            }
        },
        methods: {
            async createAccount() {
                const form = this.$refs.form;
                if(form.checkValidity()) {
                    this.loading = true;

                    let newAccount = {
                        name: this.name,
                        url: this.url,
                    };

                    const success = await this.accountsStore.createAccount(newAccount);
                    this.loading = false;
                    
                    if (success) {
                        form.reset();
                        this.formError = false;
                        this.$emit('form_submission_successful');
                    } else {
                        this.formError = true;
                    }
                } else {
                    this.validateAllFields();
                }
            }
        }
    }
</script>