<template>
    <SettingsSection cssClass="account-details-settings" :loading="loading" v-if="!hide">
        <template #title>
            <h2>{{ $t('views.account_subscriptions.invoice_documents_title') }}</h2>
        </template>

        <template #content>
            <ul v-if="!loading && invoices.length > 0" class="invoices-type-1">
                <li v-for="(invoice, index) in invoices" :key="index" >
                    <a :href="invoice.invoice_link" :class="invoice.invoice_link ? 'has-link' : 'no-link'" target="_blank" rel="noreferrer external noopener">
                        <div class="left">
                            <div class="icon-pdf-pos"></div>
                        </div>
                        <div class="right">
                            <div class="top">
                                {{ $te(`fields.options_subscriptions.options.${invoice.product_main}.text`) ? $t(`fields.options_subscriptions.options.${invoice.product_main}.text`) : invoice.product_main}}
                            </div>
                            <div class="bottom">
                                {{formatDateReverse(invoice.date)}} - €{{invoice.total}} - {{ $te(`components.invoices.statusses.${invoice.status}`) ? $t(`components.invoices.statusses.${invoice.status}`) : invoice.status}}
                            </div>
                        </div>
                    </a>
                </li>
            </ul>
        </template>
    </SettingsSection>
</template>

<script>
    import SettingsSection from '@/components/account/settings/Section';
    import { useUserStore } from '@/stores/user';
    import { useAccountStore } from '@/stores/account';
    import { formatDateReverse } from '@/utils/formatting';
    
    export default {
        name: 'InvoiceSettings',
        components: {
            SettingsSection
        },
        data() {
            return {
                loading: false,
                loaded: false,
                invoices: []
            }
        },
        computed: {
            userStore() {
                return useUserStore();
            },
            accountStore() {
                return useAccountStore();
            },
            isAdmin() {
                return this.accountStore.isAccountAdmin || this.userStore.isPartnerAdmin;
            },
            hide() {
                return !this.isAdmin || (this.loaded && this.invoices.length < 1);
            }
        },
        methods: {
            formatDateReverse,
            async fetchInvoices() {
                if(!this.isAdmin) { return; }

                this.loading = true;
                const invoices = await this.accountStore.fetchInvoices();

                if(invoices) {
                    this.invoices = invoices;
                } else {
                    this.invoices = [];
                }
                this.loading = false;
                this.loaded = true;
            }
        },
        mounted() {
            this.fetchInvoices();
        }
    }
</script>