<template>
    <WidgetSection
        ref="WidgetSection"
        :loaded="loaded"
        :loading="loading"
        v-if="!hide"
        :defaultToggle="defaultToggle"
        @loading-start="handleLoadingStart"
        loadingText="common.loading_1"
        :paywall="paywall"
    >
        <template v-slot:section-title>
            <div class="title">
                {{ $t('components.prospect_family.name')}}
            </div>
        </template>

        <template v-slot:section-content>
            <div class="row row-content">
                <div class="table-container">
                    <table class="table-type-1 companies">
                        <thead>
                            <tr class="table-header">
                                <td></td>
                                <td>{{ $t('common.company_name') }}</td>
                                <td>{{ $t('common.coc') }}</td>
                                <td>{{ $t('common.phone') }}</td>
                                <td>{{ $t('common.website') }}</td>
                                <td>{{ $t('common.address') }}</td>
                                <td>{{ $t('common.postcode') }}</td>
                                <td>{{ $t('common.city') }}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="company clickable" v-for="company in family.details" :key="company.id" @click="openCompanyProfile(company.id)">
                                <td>
                                    <div class="logo-wrp">
                                        <CompanyLogo 
                                            :domain="company.domain ? company.domain : null"
                                            :company_name="company.name ? company.name : company.domain ? company.domain : null"
                                            class="company-image"
                                        />
                                        <LabelMini 
                                            :label="company.label"
                                            v-if="company.label === 0 || company.label === 1"
                                        />
                                    </div>
                                </td>
                                <td class="company-name">{{ company.name }}</td>
                                <td>{{ company.coc || company.id }}</td>
                                <td>{{ company.phone_international || '-'}}</td>
                                <td class="website">{{ company.url || '-'}}</td>
                                <td class="address">{{ company.address || '-' }}</td>
                                <td>{{ company.postcode || '-' }}</td>
                                <td>{{ company.city || '-' }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </template>
    </WidgetSection>
</template>

<script>
    import { useProspectStore } from '@/stores/prospect';
    import { useAccountStore } from '@/stores/account';
    import { fetchUrl } from '@/services/ppApi';
    import WidgetSection from '@/components/sections/WidgetSection';
    import CompanyLogo from '@/components/elements/misc/CompanyLogo';
    import LabelMini from '@/components/elements/misc/LabelMini';
    import i18n from "@/plugins/i18n";
    import { useToastStore } from '@/stores/toast';

    export default {
        name: "ProspectFamily",
        components: {
            WidgetSection,
            CompanyLogo,
            LabelMini
        },
        props: {
            auto: {
                type: Boolean,
                default: false
            },
            defaultToggle: {
                type: Boolean,
                default: false
            },
            paywall: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                loading: false,
                loaded: false,
                abortController: null,
            }
        },
        computed: {
            paywallActive() {
                return this.paywall && useAccountStore().paywall;
            },
            hide() {
                return !this.family || (!this.loading && this.loaded && (!this.family.details || this.family.details.length < 1));
            },
            family() {
                return useProspectStore().family;
            }
        },
        methods: {
            async fetchFamily() {
                if (!this.family || this.loaded || (this.family.api_urls && this.family.api_urls.length < 1)) {
                    return;
                }

                if (this.abortController) {
                    this.abortController.abort();
                }

                this.abortController = new AbortController();
                this.loading = true;

                try {
                    const response = await fetchUrl(this.family.api_urls[0], this.abortController.signal);
                    this.family.details = response.data.companies;
                    this.loaded = true;

                    if(this.family?.details?.length > 0) {
                        useToastStore().addToast(i18n.global.t(`toasts.success.prospect_family`), 'success');
                    } else {
                        useToastStore().addToast(i18n.global.t(`toasts.error.prospect_family`), 'error'); 
                    }
                } catch {
                    this.family?.details ? this.family.details = null : '';
                    this.loaded = true; // Potential improvement: manual retry
                } finally {
                    this.loading = false;
                }
            },
            async handleLoadingStart() {
                if (!this.paywallActive && !this.loaded) {
                    await this.fetchFamily();
                    if(!this.hide && this.$refs.WidgetSection) {
                        this.$refs.WidgetSection.toggleSection();
                    }
                }
            },
            openCompanyProfile(id) {
                if(id) {
                    this.$router.push({ name: 'search-result', params: { id: id.includes('_') ? id.split('_')[0] : id } });
                }
            },
        },
        mounted() {
            if(!this.paywallActive && !this.loaded && (this.auto || this.defaultToggle)) {
                this.fetchFamily();
            }
        },
        unmounted() {
            if (this.abortController) {
                this.abortController.abort();
            }
        }
    }
</script>