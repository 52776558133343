import { defineStore } from "pinia";
import { useUserStore } from '@/stores/user';
import { updateAccount, createAccount, fetchAccounts } from "@/services/ppApi";

export const useAccountsStore = defineStore("accounts", {
    state: () => {
        const defaultOptions = {
            search: '',
            sort: '',
            status: '',
            product: ''
        };
        const defaultPagination = {
            limit: '10',
            page: 1
        };
        
        return {
            accounts: [],
            updatingOptions: false,
            loading: false,
            error: null,
            found: 0,
            total: 0,
            defaultOptions: defaultOptions,
            options: { ...defaultOptions },
            pagination: { ...defaultPagination }
        }
    },
    getters: {
        hasDefaultOptions(state) {
            return JSON.stringify(state.options) === JSON.stringify(state.defaultOptions);
        }
    },
    actions: {
        async loadAccounts() {
            this.loading = true;
            this.error = null;
            try {
                const response = await fetchAccounts({
                    text: this.options.search,
                    sort_by: this.options.sort.split('-')[0] || '',
                    sort_order: this.options.sort.split('-')[1] || '',
                    status: this.options.status,
                    product: this.options.product,
                    limit: this.pagination.limit,
                    page: this.pagination.page
                });
                this.accounts = response.data.accounts.map(account => ({
                    account_id: account.account_id,
                    url: account.url,
                    domain: account.domain,
                    name: account.name,
                    role: account.role,
                    status: account.status,
                    product: account.product,
                    product_main: account.product_main,
                    paid_to_time: account.paid_to_time
                }));
                this.found = response.data.found;
                this.total = response.data.total || this.total;
            } catch (error) {
                this.accounts = [];
                this.found = 0;
                this.total = 0;
                this.error = error;
            } finally {
                this.loading = false;
                this.updatingOptions = false;
            }
        },
        async createAccount(account) {
            try {
                const response = await createAccount(account);
                const newAccount = response.data.accounts[0];

                if (newAccount) {
                    this.accounts.unshift(newAccount);
                    return true;
                } else {
                    return false;
                }
            } catch (error) {
                return false;
            }
        },
        async editAccount(account) {
            const index = this.accounts.findIndex(a => a.account_id === account.account_id);
            if (index === -1) { return false }

            try {
                let newAccount = {
                    account_id: account.account_id,
                    name: account.name,
                    url: account.url
                }

                if(useUserStore().isPartnerGod && this.accounts[index].product_main === 'free' && account.product.includes('trial')) {
                    newAccount = {
                        ...newAccount,
                        product: account.product,
                        paid_to: account.paid_to_time
                    };
                } else if(useUserStore().isPartnerGod && this.accounts[index].product_main === 'trial' && account.product.includes('trial')) {
                    newAccount = {
                        ...newAccount,
                        paid_to: account.paid_to_time
                    };
                }

                if((account.role && account.role < 15) || useUserStore().isPartnerAdmin) {
                    newAccount = {
                        ...newAccount,
                        status: account.status
                    };
                }

                const response = await updateAccount(newAccount);
                const updatedAccount = response.data.accounts[0];

                if (updatedAccount) {
                    if (index !== -1) {
                        this.accounts[index] = { ...this.accounts[index], ...updatedAccount };
                    }
                    return true;
                } else {
                    return false;
                }
            } catch (error) {
                return false;
            }
        },
        updateOptions(newOptions) {
            this.options = { ...this.options, ...newOptions };
            this.pagination.page = 1;
            this.loadAccounts();
        },
        setUpdatingOptions(updating) {
            this.updatingOptions = updating;
        },
        updatePagination(newPagination) {
            this.pagination = { ...this.pagination, ...newPagination };
            this.loadAccounts();
        },
        resetOptions() {
            this.options = { ...this.defaultOptions };
            this.pagination.page = 1;
            this.loadAccounts();
        }
    }
});
