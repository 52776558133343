<template>
    <div :class="['row', 'account', editable ? 'editable' : 'not-editable', currentStatus]" @click="selectAccount">
        <div class="item-image">
            <CompanyLogo 
                :domain="account.domain ? account.domain : null"
                :company_name="account.name ? account.name : account.domain ? account.domain : null"
            />
        </div>

        <div class="content">
            <div class="account-name">{{ account.name || '-' }}</div>
            <p class="account-url">{{ account.url }}{{ editable && account.product ? ' - ' + account.product : '' }}{{ editable && account.paid_to_time ? ' - ' + timestampToDisplayDate(account.paid_to_time) : '' }}</p>
        </div>

        <div class="actions">
            <div class="btn-1 btn-s-icon" v-if="editable" @click.stop="editAccountPopup(account)">
                <div class="icon-edit-neg"></div>
            </div>
            <div class="btn-1 btn-s-icon card-action">
                <div class="icon-arrow-neg"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'pinia';
    import { useUserStore } from '@/stores/user';
    import { useAccountStore } from '@/stores/account';
    import router from '@/router';
    import CompanyLogo from '@/components/elements/misc/CompanyLogo';
    import i18n from "@/plugins/i18n";
    import { usePopupStore } from '@/stores/popup';
    import { useToastStore } from '@/stores/toast';
    import { markRaw } from 'vue';
    import AccountPartnerForm from '@/components/forms/AccountPartnerForm';
    import { timestampToDisplayDate } from '@/utils/dates';

    export default {
        name: 'AccountsListItem',
        components: {
            CompanyLogo
        },
        props: {
            account: {
                type: Object,
                required: true,
            },
        },
        computed: {
            currentStatus() {
                switch (this.account.status) {
                    case -1:
                        return 'deactivated';
                    case 0:
                        return 'inactive';
                    case 1:
                    default:
                        return 'active';
                }
            },
            editable() {
                const userStore = useUserStore();
                return userStore.isPartnerAdmin && userStore.partnerRole < 20;
            }
        },
        methods: {
            timestampToDisplayDate,
            ...mapActions(useAccountStore, ['setAccount']),
            handleClick() {
                if(!this.editable) {
                    this.selectAccount();
                }
            },
            selectAccount() {
                this.setAccount({ account_id: this.account.account_id });
                router.push({ name: 'prospects' });
            },
            editAccountPopup(account) {
                usePopupStore().showPopup('form', {
                    title: 'popups.edit_account.title',
                    description: 'popups.edit_account.description',
                    formComponent: markRaw(AccountPartnerForm),
                    formProps: {
                        account: account
                    },
                    onFormSuccess: () => {
                        useToastStore().addToast(i18n.global.t('toasts.success.account_updated'), 'success');
                    }
                });
            },
        },
    };
</script>