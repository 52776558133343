<template>
    <div :class="['stars-review-type-1', fullWidth ? 'full-width' : '']">
        <ul v-if="showStars" class="review-stars">
            <li v-for="n in scale" :key="n">
                <div :class="['review-icon', getStarClass(n)]"></div>
            </li>
        </ul>
        <div class="review-text">
            <span class="score-main">{{ score }}</span>
            <span class="score-slash">/</span>
            <span class="score-scale">{{ scale }}</span>
            <span v-if="total && total > 0" class="score-total">({{ formatNumber(total) }} reviews)</span>
        </div>
    </div>
</template>

<script>
import { formatNumber } from '@/utils/formatting';

export default {
    name: "StarsReview",
    props: {
        score: {
            type: [Number, String],
            default: 0
        },
        scale: {
            type: Number,
            default: 5
        },
        total: {
            type: [Number, String],
            default: 0
        },
        fullWidth: {
            type: Boolean,
            default: false
        },
        showStars: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        parsedScore() {
            return parseFloat(this.score) || 0;
        }
    },
    methods: {
        formatNumber,
        getStarClass(n) {
            const starValue = n * 2; // Each star represents two points (0.5 increments)
            const scoreValue = this.parsedScore * 2; // Convert score to match starValue range

            if (scoreValue >= starValue) {
                return 'star-full';
            } else if (scoreValue >= starValue - 1) {
                return 'star-half';
            } else {
                return 'star-empty';
            }
        }
    }
}
</script>