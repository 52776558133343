<template>
    <AccountViewBase :account="account" :loading="loading" :error="error">
        <div class="container-fluid content-type-24">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2 col-xl-6 col-xl-offset-3">
                        <div class="content">
                            <div class="img success">
                                <div class="icon-check-pos"></div>
                            </div>
                            <h1 class="h1">{{$t('views.payment_success.title')}}</h1>
                            <p class="p">{{$t('views.payment_success.description')}}</p>
                            <router-link :to="{name: 'subscriptions'}" class="btn-m btn-1">{{$t('views.payment_success.buttons.subscriptions')}}</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </AccountViewBase>
</template>

<script>
    import AccountViewBase from '@/views/account/AccountViewBase';
    import i18nViewMixin from '@/mixins/i18nViewMixin';
    import accountMixin from '@/mixins/accountMixin';
    import { useOrderStore } from '@/stores/order';
    import { scrollToTop } from '@/utils/scroll';

    export default {
        name: 'AccountSettingsView',
        components: {
            AccountViewBase
        },
        mixins: [i18nViewMixin, accountMixin],
        data() {
            return {
                seo_title: 'views.payment_success.seo_title',
            };
        },
        computed: {
            orderStore() {
                return useOrderStore();
            }
        },
        mounted() {
            scrollToTop();
            this.orderStore.saveOrderSettings(true);
            this.orderStore.resetOrder();
        }
    }
</script>
