<template>
    <WidgetSection
        ref="WidgetSection"
        :loaded="loaded"
        :loading="loading"
        v-if="!hide"
        :defaultToggle="defaultToggle"
        @toggle="handleToggle"
        @loading-start="handleLoadingStart"
        cssClass="slider-type-1"
        loadingText="common.loading_1"
        :paywall="paywall"
    >
        <template v-slot:section-title>
            <div class="title">
                {{ $t('components.prospect_news.name')}}
            </div>
        </template>

        <template v-slot:section-actions>
            <div class="swiper-navigation">
                <div :class="`swiper-button-prev-${uniqueId}`"></div>
                <div :class="`swiper-button-next-${uniqueId}`"></div>
            </div>
        </template>

        <template v-slot:section-content>
            <div class="row row-slider">
                <swiper-container ref="swiper" init="false">
                    <swiper-slide v-for="(newsItem, index) in news.details" :key="index">
                        <component :is="newsItem.url ? 'a' : 'div'" :href="newsItem.url || undefined" target="_blank" rel="noreferrer external" class="slide-container">
                            <div class="bar">
                                <div class="left">
                                    <CompanyLogo 
                                        :domain="newsItem.domain ? newsItem.domain : null"
                                        :company_name="newsItem.domain ? newsItem.domain : null"
                                    />
                                </div>
                                <div class="right">
                                    <div class="title" v-if="newsItem.title" v-html="highlightDescription(newsItem.title)"></div>
                                    <div class="date" v-if="newsItem.date">{{ formatDateReverse(newsItem.date) }}</div>
                                </div>
                            </div>
                            <div class="description news" v-if="newsItem.description" v-html="highlightDescription(newsItem.description || '')"></div>
                            <div class="url" v-if="newsItem.url">{{ newsItem.url }}</div>
                        </component>
                    </swiper-slide>
                </swiper-container>
            </div>
        </template>
    </WidgetSection>
</template>

<script>
    import { useProspectStore } from '@/stores/prospect';
    import { useAccountStore } from '@/stores/account';
    import { fetchUrl } from '@/services/ppApi';
    import { nextTick } from 'vue';
    import WidgetSection from '@/components/sections/WidgetSection';
    import CompanyLogo from '@/components/elements/misc/CompanyLogo';
    import { formatDateReverse } from '@/utils/formatting';
    import i18n from "@/plugins/i18n";
    import { useToastStore } from '@/stores/toast';

    export default {
        name: "ProspectApps",
        components: {
            WidgetSection,
            CompanyLogo
        },
        props: {
            auto: {
                type: Boolean,
                default: false
            },
            defaultToggle: {
                type: Boolean,
                default: false
            },
            paywall: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                loading: false,
                loaded: false,
                abortController: null,
            }
        },
        computed: {
            news() {
                return useProspectStore().news;
            },
            paywallActive() {
                return this.paywall && useAccountStore().paywall;
            },
            hide() {
                return !this.news || (!this.loading && this.loaded && (!this.news.details || this.news.details.length < 1));
            },
            uniqueId() {
                return Math.random().toString(36).substr(2, 9);
            },
        },
        methods: {
            formatDateReverse,
            highlightDescription(text) {
                return text.replace(/\*\*(.*?)\*\*/g, '<span class="highlight">$1</span>');
            },
            async fetchNews() {
                if (!this.news || (this.news.api_urls && this.news.api_urls.length < 1) || this.loaded) {
                    return;
                }

                if (this.abortController) {
                    this.abortController.abort();
                }

                this.abortController = new AbortController();
                this.loading = true;

                try {
                    const response = await fetchUrl(this.news.api_urls[0], this.abortController.signal);
                    this.news.details = response.data.news || '';
                    this.loaded = true;

                    if(this.news?.details?.length > 0) {
                        useToastStore().addToast(i18n.global.t(`toasts.success.prospect_news`), 'success');
                    } else {
                        useToastStore().addToast(i18n.global.t(`toasts.error.prospect_news`), 'error'); 
                    }
                } catch {
                    this.news?.details ? this.news.details = null : '';
                    this.loaded = true; // Potential improvement: manual retry
                } finally {
                    this.loading = false;
                    await nextTick();
                    this.initSwiper();
                }
            },
            initSwiper() {
                if(!this.$refs.swiper) { return; }
                
                const swiperEl = this.$refs.swiper;
                const swiperParams = {
                    slidesPerView: 1,
                    spaceBetween: 24,
                    breakpoints: {
                        769: {
                            slidesPerView: 2,
                            slidesPerGroup: 2
                        },
                        992: {
                            slidesPerView: 3,
                            slidesPerGroup: 3
                        }
                    },
                    navigation: {
                        nextEl: `.swiper-button-next-${this.uniqueId}`,
                        prevEl: `.swiper-button-prev-${this.uniqueId}`
                    }
                };
                Object.assign(swiperEl, swiperParams);
                swiperEl.initialize();
            },
            handleToggle(isVisible) {
                if (isVisible) {
                    this.$nextTick(() => {
                        this.initSwiper();
                    });
                }
            },
            async handleLoadingStart(isVisible) {
                if(this.paywallActive) { return; }

                if (!this.loaded) {
                    await this.fetchNews();
                    if(!this.hide && this.$refs.WidgetSection) {
                        this.$refs.WidgetSection.toggleSection();
                    }
                } else {
                    this.handleToggle(isVisible);
                }
            }
        },
        mounted() {
            if(!this.paywallActive &&  !this.loaded && (this.auto || this.defaultToggle)) {
                this.fetchNews();
            }
        },
        unmounted() {
            if (this.abortController) {
                this.abortController.abort();
            }
        }
    }
</script>