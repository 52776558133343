import axios from 'axios';
import { useUserStore } from '@/stores/user';
import { confirmAuthPopup } from '@/utils/confirmAuthPopup';

export const ppApi = axios.create({
    baseURL: 'https://api.prospectpro.nl/v1.1'
});

window.activeFetches = 0;

ppApi.interceptors.request.use((config) => {
    window.activeFetches += 1;
    const userStore = useUserStore();
    const token = userStore.token;
    const defaultParams = { front: 10, pid: '0000' };

    if(!config.skipDefaultParams) {
        config.params = { 
            ...defaultParams,
            ...config.params
        };
    }
    
    if (token) {
        config.headers.Authorization = `Bearer ${ token }`;
    }

    return config;
});

ppApi.interceptors.response.use(
    response => {
        window.activeFetches = Math.max(window.activeFetches - 1, 0);
        return response;
    },
    error => {
        window.activeFetches = Math.max(window.activeFetches - 1, 0);
        if (axios.isCancel(error)) {
            return Promise.resolve();
        } else if (error.response && error.response.data.error_code === "login_required") {
            confirmAuthPopup();
        } else if (error.response && error.response.data.error_code === "token_expired") {
            const userStore = useUserStore();
            userStore.reauthenticate();
        }
        return Promise.reject(error);
    }
);

export const fetchUrl = ( url, signal ) => ppApi.get(url, { signal, skipDefaultParams: true });

export const fetchCaptchaToken = ( captcha ) => ppApi.get('/opentoken', { params: { recaptcha_response: captcha }});

export const login = (email, password) => ppApi.post('/login', { email, password });
export const register = (name, url, email, password) => ppApi.post('/register', { name, url, email, password });
export const passwordReset = (email) => ppApi.post('/password_link', { email });
export const passwordSelect = (password, r) => ppApi.post('/password_reset', { password, r });
export const passwordChange = (password, passwordNew, passwordConfirm) => ppApi.patch('/user', {
  'password-old': password,
  'password': passwordNew,
  'password-confirm': passwordConfirm
});

export const fetchAccountApiKey = ( account_id ) => ppApi.get('/account_key', { params: { account_id }});
export const deleteAccountApiKey = ( account_id ) => ppApi.delete('/account_key', { params: { account_id } });

export const validateAccountTracker = ( account_id ) => ppApi.get('/tracker', { params: { account_id }});

export const fetchAccountInvoices = ( account_id ) => ppApi.get('/invoices', { params: { account_id }});
export const validateCoupon = ( coupon ) => ppApi.get('/coupons', { params: { coupon }});
export const createSubscription = async (order = {}) => ppApi.post('/subscriptions', order);
export const fetchSubscriptions = ( account_id, status = 'new,paid,active' ) => ppApi.get('/subscriptions', { params: { account_id, status }});
export const updateSubscription = (params = {}) => ppApi.patch('/subscriptions', params);
export const deleteSubscription = (params = {}) => ppApi.delete('/subscriptions', { params });

export const fetchAccounts = async (params = {}) => ppApi.get('/accounts', { params });
export const createAccount = async (account = {}) => ppApi.post('/accounts', account);
export const fetchAccount = async (account_id) => ppApi.get('/accounts', { params: { account_id } });
export const updateAccount = ( account = {} ) => ppApi.patch('/accounts', account);

export const fetchUsers = async (params = {}) => ppApi.get('/user', { params });
export const createUser = (user = {}) => ppApi.post('/user', user);
export const updateUser = (user = {}) => ppApi.patch('/user', user);
export const deleteUser = (user = {}) => ppApi.delete('/user', { params: user });

export const fetchCompanies = async (params = {}) => ppApi.get('/companies', { params });

export const fetchProspects = async (params = {}) => ppApi.get('/prospects', { params });
export const downloadProspects = async (params = {}) => ppApi.get('/prospects', { params });
export const updateProspect = (prospect = {}) => ppApi.patch('/prospects', prospect);
export const fetchSuspects = async (params = {}) => ppApi.get('/suspects', { params: {...params, ...{ front: 10 }} });

export const fetchAccountTags = ( account_id ) => ppApi.get('/prospects', { 
    params: {
        account_id,
        taglist: "map"
    }
});

export const fetchSuggestions = async (params = {}) => ppApi.get('/suggest', { params });

export const fetchPeople = async (params = {}, signal) => ppApi.get('/people', { params, signal });
export const fetchContacts = async (params = {}, signal) => ppApi.get('/contacts', { params, signal });
export const addContact = (contact = {}) => ppApi.post('/contacts', contact);
export const editContact = (contact = {}) => ppApi.patch('/contacts', contact);
export const deleteContact = (contact = {}) => ppApi.delete('/contacts', { params: contact });

export const fetchEvents = async (params = {}, signal) => ppApi.get('/event', { params, signal });
export const addEvent = async (params = {}) => ppApi.post('/event', params);
export const updateEvent = async (params = {}) => ppApi.patch('/event', params);
export const deleteEvent = async (params = {}) => ppApi.delete('/event', { params });

export const fetchPageviews = async (params = {}, signal) => ppApi.get('/leads', { params, signal });