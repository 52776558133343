import i18n from "@/plugins/i18n";
import { format, isToday, isYesterday, addDays } from 'date-fns';
import { enUS, nl } from 'date-fns/locale';

export function dateOptionToEpoch(option) {
    const currentYear = new Date().getFullYear();
    const lastYear = currentYear - 1;
    const today = new Date();

    if(option === 'all') {
        return null;
    } else if (option.startsWith('d_')) {
        const daysAgo = parseInt(option.split('_')[1], 10);
        const date = new Date(today);
        date.setDate(today.getDate() - daysAgo);
        return Math.floor(date.getTime() / 1000);
    } else if (option === 'y_current') {
        const startOfYear = new Date(currentYear, 0, 1);
        return Math.floor(startOfYear.getTime() / 1000);
    } else if (option === 'y_last') {
        const startOfLastYear = new Date(lastYear, 0, 1);
        const endOfLastYear = new Date(lastYear, 11, 31, 23, 59, 59);
        return `${Math.floor(startOfLastYear.getTime() / 1000)}:${Math.floor(endOfLastYear.getTime() / 1000)}`;
    } else {
        return option;
    }
}

export function timestampToDisplayDate(timestamp, showTime = true) {
    const seconds = parseFloat(timestamp);
    if (isNaN(seconds)) return false;

    const date = new Date(seconds * 1000);

    const dateFnsLocales = {
        en: enUS,
        nl: nl,
    };

    const currentLocale = dateFnsLocales[i18n.global.locale] || nl;

    const time = format(date, 'HH:mm', { locale: currentLocale });

    return  isToday(date) ? i18n.global.t('common.today') + (showTime ? ' - ' +  time : ''):
            isYesterday(date) ? i18n.global.t('common.yesterday') + (showTime ? ' - ' +  time : ''):
            format(date, 'dd-MM-yyyy', { locale: currentLocale }) + (showTime ? ' - ' +  time : '');
}

export function addDaysToEpoch(epochSeconds, daysToAdd) {
    // Convert epoch seconds to milliseconds and create a Date object
    const date = new Date(epochSeconds);
  
    // Add the specified number of days using date-fns
    const newDate = addDays(date, daysToAdd);
  
    // Format the new date as 'yyyy-MM-dd' using date-fns
    return format(newDate, 'yyyy-MM-dd');
}