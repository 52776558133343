<template>
    <div class="form-type-0">
        <form @submit.prevent="setSubscriptionType" @input="onInputChange" ref="form" novalidate>
            <CustomRadio
                v-model="subscriptionType"
                @update:modelValue="updateSubscription"
                inputKey="subscriptions"
                ref="subscriptions"
                :options="subscriptionsOptions"
                cssClass="custom-radio-type-4"
                :hideNonSelected="hideNonSelected"
                required
            />

            <button v-if="(!hideSaveButton || newSubscriptionSelected) && !hideNonSelected" type="submit" :disabled="loading || !newSubscriptionSelected">
                <span v-if="loading" class="loading-spinner-type-1"></span>
                <span v-else>{{ $t(btnLabel) }}</span>
            </button>
            <p v-if="formError" class="form-error">{{ $t('forms.subscription_select.error') }}</p>
            <p v-if="formSuccess" class="form-success">{{ $t('forms.subscription_select.success') }}</p>
        </form>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'pinia';
    import { useOrderStore } from '@/stores/order';
    import { useAccountStore } from '@/stores/account';
    import CustomRadio from '@/components/elements/inputs/CustomRadio';
    import formMixin from '@/mixins/formMixin';
    import { timestampToDisplayDate } from '@/utils/dates';
    import { formatNumber } from '@/utils/formatting';

    export default {
        mixins: [formMixin],
        emits: ['saved'],
        props: {
            hideSaveButton: {
                type: Boolean,
                default: false
            },
            hideNonSelected: {
                type: Boolean,
                default: false
            },
            btnLabel: {
                type: String,
                default: 'forms.subscription_select.button'
            }
        },
        components: {
            CustomRadio
        },
        data() {
            return {
                subscriptionType: '',
                subscription: {}
            }
        },
        computed: {
            ...mapState(useOrderStore, ['newSubscriptionSelected', 'activeDiscount']),
            ...mapState(useOrderStore, {defaultSubscriptionType: 'subscriptionType'}),
            accountStore() {
                return useAccountStore();
            },
            subscriptionsOptions() {
                return [
                    // {
                    //     value: 'tst',
                    //     text: 'fields.options_subscriptions.options.free.text',
                    //     description: 'fields.options_subscriptions.options.free.description',
                    //     price: {
                    //         label: 'fields.options_subscriptions.options.free.price',
                    //         value: formatNumber(0.00)
                    //     },
                    //     image: 'account',
                    //     bullets: [
                    //         {
                    //             text: 'fields.options_subscriptions.options.free.bullets.one',
                    //         },
                    //         {
                    //             text: 'fields.options_subscriptions.options.free.bullets.two',
                    //         },
                    //         {
                    //             text: 'fields.options_subscriptions.options.free.bullets.three',
                    //             class: 'cross'
                    //         },
                    //         {
                    //             text: 'fields.options_subscriptions.options.free.bullets.four',
                    //             class: 'cross'
                    //         }
                    //     ],
                    //     ...(
                    //         (this.accountStore.productType === 'tst' || this.subscriptionType === 'free') &&
                    //         {
                    //             notice: {
                    //                 label: this.accountStore.productType === 'free' ? 'fields.options_subscriptions.notice.duration_unlimited' : 'fields.options_subscriptions.notice.start_after_current',
                    //                 translationLabels: this.accountStore.productType === 'free' ? {} : this.accountStore.account.paid_to_time ? { renewalDate: timestampToDisplayDate(this.accountStore.account.paid_to_time, false) } : {}
                    //             }
                    //         }
                    //     ),
                    // },
                    {
                        value: 'frl',
                        text: 'fields.options_subscriptions.options.freelancer.text',
                        description: 'fields.options_subscriptions.options.freelancer.description',
                        price: {
                            label: 'fields.options_subscriptions.options.freelancer.price',
                            value: formatNumber(this.activeDiscount ? ((100 - parseFloat(this.activeDiscount)) * 99 / 100).toFixed(2) : 99)
                        },
                        image: 'cards',
                        bullets: [
                            {
                                text: 'fields.options_subscriptions.options.freelancer.bullets.one',
                            },
                            {
                                text: 'fields.options_subscriptions.options.freelancer.bullets.two',
                            },
                            {
                                text: 'fields.options_subscriptions.options.freelancer.bullets.three',
                            },
                            {
                                text: 'fields.options_subscriptions.options.freelancer.bullets.four',
                            },
                            {
                                text: 'fields.options_subscriptions.options.freelancer.bullets.five',
                            }
                        ],
                        ...(
                            (this.subscription.product_main === 'frl' || this.subscriptionType === 'frl') &&
                            {
                                notice: {
                                    css: (this.subscription.product_main === 'frl' && this.subscriptionType === 'unl') ? 'error' : '',
                                    label: this.subscription.product_main === 'frl' && this.subscription.status === 'canceled' && this.subscriptionType === 'frl' ?
                                            'fields.options_subscriptions.notice.start_direct' :
                                            this.subscription.product_main === 'frl' && this.subscriptionType === 'frl' ?
                                            'fields.options_subscriptions.notice.duration_renewal' :
                                            this.subscription.product_main === 'frl' && this.subscription.status === 'canceled' && this.subscriptionType === 'unl' ?
                                            '' :
                                            this.subscription.product_main === 'frl' && this.subscriptionType === 'unl' ?
                                            'fields.options_subscriptions.notice.cancel_direct' :
                                            this.subscription.product_main === 'unl' && this.subscriptionType === 'frl' ?
                                            'fields.options_subscriptions.notice.start_after_current' :
                                            this.subscription.product_main !== 'frl' && this.subscriptionType === 'frl' ?
                                            'fields.options_subscriptions.notice.start_direct' :
                                            '',
                                    translationLabels: this.accountStore.account.paid_to_time ?
                                            { renewalDate: timestampToDisplayDate(this.accountStore.account.paid_to_time, false) } :
                                            {}
                                }
                            }
                        ),
                    },
                    {
                        value: 'unl',
                        text: 'fields.options_subscriptions.options.unlimited.text',
                        description: 'fields.options_subscriptions.options.unlimited.description',
                        price: {
                            label: 'fields.options_subscriptions.options.unlimited.price',
                            value: formatNumber(this.activeDiscount ? ((100 - parseFloat(this.activeDiscount)) * 499 / 100).toFixed(2) : 499)
                        },
                        image: 'infinity',
                        bullets: [
                            {
                                text: 'fields.options_subscriptions.options.unlimited.bullets.one',
                            },
                            {
                                text: 'fields.options_subscriptions.options.unlimited.bullets.two',
                            },
                            {
                                text: 'fields.options_subscriptions.options.unlimited.bullets.three',
                            },
                            {
                                text: 'fields.options_subscriptions.options.unlimited.bullets.four',
                            },
                            {
                                text: 'fields.options_subscriptions.options.unlimited.bullets.five',
                            }
                        ],
                        ...(
                            (this.subscription.product_main === 'unl' || this.subscriptionType === 'unl') &&
                            {
                                notice: {
                                    css: (this.subscription.product_main === 'unl' && this.subscription.status === 'canceled' && this.subscriptionType === 'unl') ||
                                    (this.subscription.product_main === 'unl' && this.subscriptionType === 'frl') ? 'error' : '',
                                    label: this.subscription.product_main === 'unl' && this.subscription.status === 'canceled' && this.subscriptionType === 'unl' ?
                                            'fields.options_subscriptions.notice.duration_canceled' :
                                            this.subscription.product_main === 'unl' && this.subscriptionType === 'unl' ?
                                            'fields.options_subscriptions.notice.duration_renewal' :
                                            this.subscription.product_main === 'unl' && this.subscriptionType === 'frl' ?
                                            'fields.options_subscriptions.notice.duration_canceled' :
                                            this.subscription.product_main !== 'unl' && this.subscriptionType === 'unl' ?
                                            'fields.options_subscriptions.notice.start_direct' :
                                            '',
                                    translationLabels: this.accountStore.account.paid_to_time ?
                                            { renewalDate: timestampToDisplayDate(this.accountStore.account.paid_to_time, false) } :
                                            {}
                                }
                            }
                        )
                    },
                ]
            }
        },
        methods: {
            ...mapActions(useOrderStore, ['updateSubscription', 'setSubscription', 'generateProduct']),
            formatNumber,
            setSubscriptionType() {
                const form = this.$refs.form;
                if(form.checkValidity()) {
                    this.setSubscription(this.subscriptionType);
                    
                    const product = this.generateProduct(this.subscriptionType);
                    const index = this.subscriptionsOptions.findIndex(subscriptionsOption => subscriptionsOption.value === this.subscriptionType);
                    
                    if(index !== -1) {
                        this.$emit('saved', {
                            product,
                            productType: this.subscriptionType,
                            price: this.subscriptionsOptions[index].price.value,
                            name: this.subscriptionsOptions[index].text
                        });
                    }
                } else {
                    this.validateAllFields();
                }
            },
            async currentSubscription() {
                return await this.accountStore.getSubscription();
            },
            async setDefault() {
                this.subscriptionType = this.defaultSubscriptionType;

                this.subscription = await this.currentSubscription() || {
                    product_main: this.accountStore.productType
                };
            }
        },
        mounted() {
            this.setDefault();
        }
    }
</script>
