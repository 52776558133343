<template>
    <template v-if="!loading && !error && found > 0">
        <div class="container-fluid item-lists-type-2">
            <div class="container">
                <PartnerUsersListItem
                    v-for="user in displayedUsers"
                    :key="user.uid"
                    :user="user"
                />
            </div>
        </div>
    </template>

    <NoResultsSection
        v-else-if="!loading && !error"
        :message="options.search ? 'views.partner_users.no_results_search' : ''"
        :translationPlaceholders="options.search ? { query: `<span>${options.search}</span>` } : {}"
    />
    <ErrorSection v-else-if="error" :error="error" />
    <LoadingSection v-else />
</template>

<script>
    import { mapState, mapActions } from 'pinia';
    import { useUsersStore } from '@/stores/users';
    import PartnerUsersListItem from '@/components/admin/partnerAdmins/PartnerUsersListItem';
    import ErrorSection from '@/components/sections/ErrorSection';
    import LoadingSection from '@/components/sections/LoadingSection';
    import NoResultsSection from '@/components/sections/NoResultsSection';
    import { scrollToTop } from '@/utils/scroll';

    export default {
        name: 'PartnerUsersList',
        components: {
            PartnerUsersListItem,
            ErrorSection,
            LoadingSection,
            NoResultsSection
        },
        data() {
            return {
                displayedUsers: []
            };
        },
        computed: {
            ...mapState(useUsersStore, ['users', 'loading', 'error', 'found', 'options']),
        },
        methods: {
            ...mapActions(useUsersStore, ['fetchUsers']),
        },
        created() {
            this.fetchUsers();
        },
        watch: {
            users: {
                handler(newUsers) {
                    scrollToTop();
                    this.displayedUsers = [];
                    newUsers.forEach((user, index) => {
                        setTimeout(() => {
                            this.displayedUsers.push(user);
                        }, index * 50 < 500 ? index * 50 : 500);
                    });
                },
                deep: true
            }
        },
    };
</script>
