<template>
    <section class="container-fluid header-list-type-2">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="wrp">
                        <div class="content">
                            <h1 class="name">{{ $t('views.partner_users.title') }}</h1>
                            <p>{{ $t('views.partner_users.description') }}</p>
                        </div>
                        <div class="actions">
                            <PartnerUserCreate />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import PartnerUserCreate from '@/components/admin/partnerAdmins/PartnerUserCreate';

export default {
    name: "PartnerUserHeader",
    components: {
        PartnerUserCreate
    }
}
</script>