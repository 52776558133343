import { defineStore } from 'pinia';

export const useScreenWidthStore = defineStore('screenWidth', {
    state: () => ({
        width: window.innerWidth
    }),
    getters: {
        hasLargeScreen: (state) => state.width >= 992 || false,
    },
    actions: {
        updateWidth() {
            this.width = window.innerWidth;
        }
    }
});