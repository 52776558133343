<template>
    <div class="col-12 col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3">
        <div class="content">
            <InvoiceDetails />
        </div>
    </div>
    <div class="col-12 col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3">
        <div class="content">
            <SubscriptionForm :hideSaveButton="true" :hideNonSelected="true" />
        </div>
    </div>
    <div class="col-12 col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3">
        <SubscriptionCoupon :coupon="coupon" @newCoupon="setCoupon" />
    </div>
    <div class="col-12 col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3">
        <button class="btn-1 btn-m btn-block btn-checkout" @click.prevent="initPayment"><span v-if="!loading">{{ $t('views.account_order.steps.summary.button')}}</span><div v-if="loading" class="loading-spinner-type-1"></div></button>
    </div>
</template>

<script>
    import { useOrderStore } from '@/stores/order';
    import { mapState, mapActions } from 'pinia';
    import SubscriptionForm from '@/components/forms/SubscriptionForm';
    import InvoiceDetails from '@/components/elements/account/InvoiceDetails';
    import SubscriptionCoupon from '@/components/elements/order/SubscriptionCoupon';

    export default {
        name: 'AccountOrderCheckout',
        emits: ['newCoupon', 'startPayment'],
        components: {
            SubscriptionForm,
            InvoiceDetails,
            SubscriptionCoupon
        },
        data() {
            return {
                loading: false
            }
        },
        computed: {
            ...mapState(useOrderStore, ['coupon', 'subscriptionType']),
        },
        methods: {
            ...mapActions(useOrderStore, ['setCoupon', 'startPayment']),
            async initPayment() {
                this.loading = true;
                await this.startPayment();
                this.loading = false;
            }
        }
    }
</script>