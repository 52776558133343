<template>
    <SettingsSection cssClass="account-details-settings" :loading="loading">
        <template #title>
            <h2>{{ $t('views.account_subscriptions.subscription_settings_title') }}</h2>
        </template>

        <template #content>
            <div class="products-type-1" :class="[subscription.status === 'canceled' ? 'error' : subscription.status === 'new' ? 'success' : '']">
                <div class="left">
                    <div class="icon-card-pos"></div>
                </div>
                <div class="right">
                    <div class="name">{{$t(`fields.options_subscriptions.options.${product_main}.text`)}}</div>
                    <div class="price" v-if="subscription">{{$t(`fields.options_subscriptions.options.${product_main}.price`, { value: subscription.price })}}</div>
                    <div class="description">{{$t(`fields.options_subscriptions.options.${product_main}.description`)}}</div>
                    <div class="notice" :class="notice.type">{{$t(notice.label, notice.translationLabels)}}</div>
                </div>
            </div>
            <router-link :to="{ name: 'subscription-change' }" v-if="subscription.order_id && !product?.manual && subscription.status !== 'canceled' && subscription.status !== 'new'" class="btn-1 btn-block btn-m">{{$t('views.account_subscriptions.button_switch_subscriptions')}}</router-link>
            <router-link :to="{ name: 'subscription-order' }" v-else-if="!product?.manual && subscription.status !== 'canceled' && subscription.status !== 'new'" class="btn-1 btn-block btn-m">{{$t('views.account_subscriptions.button_new_subscriptions')}}</router-link>
            <router-link :to="{ name: 'subscription-order' }" v-else-if="subscription.status === 'canceled' && subscription.status !== 'new'" class="btn-1 btn-block btn-m">{{$t('views.account_subscriptions.button_new_subscriptions_alt')}}</router-link>
            <div @click="cancelSubscriptionPopup" v-if="subscription.order_id && !product?.manual && subscription.status !== 'canceled'" class="btn-6 btn-block btn-m">{{$t('views.account_subscriptions.button_cancel_subscriptions')}}</div>
        </template>
    </SettingsSection>
</template>

<script>
    import { mapState } from 'pinia';
    import { usePopupStore } from '@/stores/popup';
    import SettingsSection from '@/components/account/settings/Section';
    import { useAccountStore } from '@/stores/account';
    import { timestampToDisplayDate } from '@/utils/dates';
    import i18n from "@/plugins/i18n";
    import { useToastStore } from '@/stores/toast';
    
    export default {
        name: 'InvoiceSettings',
        components: {
            SettingsSection
        },
        data() {
            return {
                loading: false,
                loaded: false,
            }
        },
        computed: {
            ...mapState(useAccountStore, ['subscriptions', 'hasSubscriptions']),
            ...mapState(useAccountStore, {
                activeProduct: 'product',
                activeProductType: 'productType',
                paid_to_time: 'paid_to_time'
            }),
            accountStore() {
                return useAccountStore();
            },
            toastStore() {
                return useToastStore();
            },
            subscription() {
                return this.subscriptions?.length > 0 ? this.subscriptions[0] : false;
            },
            product() {
                let product = this.subscription?.product || this.activeProduct;

                const parts = product.split('_');

                if(parts.length === 3) {
                    return {
                        type: parts[1],
                        manual: parts[0] === 'pp' ? false : true
                    };
                } else {
                    return false;
                }
            },
            product_main() {
                if(this.product.manual) { return 'custom' }

                let productMain = this.subscription?.product_main || this.activeProductType;

                switch (productMain) {
                    case 'frl':
                        return 'freelancer';
                    case 'unl':
                        return 'unlimited';
                    case 'trial':
                        return 'trial';
                    case 'tst':
                        return 'unlimited';
                    default:
                        return 'free';
                }
            },
            notice() {
                if(this.product.manual) {
                    return {
                        label: 'fields.options_subscriptions.notice.duration_unknown'
                    }
                } else if(this.product_main === 'free') {
                    return {
                        type: 'error',
                        label: 'fields.options_subscriptions.notice.duration_unlimited'
                    }
                } else if(this.product_main === 'trial') {
                    return {
                        label: 'fields.options_subscriptions.notice.duration_canceled',
                        translationLabels: { renewalDate: timestampToDisplayDate(this.paid_to_time, false) }
                    }
                } else if(this.subscription.canceled_time) {
                    return {
                        type: 'error',
                        label: 'fields.options_subscriptions.notice.duration_canceled',
                        translationLabels: { renewalDate: timestampToDisplayDate(this.subscription.paid_to_time || this.subscription.canceled_time, false) }
                    }
                } else if (this.subscription.paid_to_time) {
                    return {
                        label: 'fields.options_subscriptions.notice.duration_renewal',
                        translationLabels: { renewalDate: timestampToDisplayDate(this.subscription.paid_to_time, false) }
                    }
                } else if (this.subscription.status === 'new' && this.subscription.product_main === 'frl') {
                    return {
                        type: 'success',
                        label: 'fields.options_subscriptions.notice.downgrade_in_progress',
                    }
                } else if (this.subscription.status === 'new') {
                    return {
                        type: 'success',
                        label: 'fields.options_subscriptions.notice.upgrade_in_progress',
                    }
                } else {
                    return {
                        label: 'fields.options_subscriptions.notice.duration_limited',
                    }
                }
            },
        },
        methods: {
            async fetchSubscriptions() {
                this.loading = true;
                await this.accountStore.getSubscriptions();
                this.loading = false;
                this.loaded = true;
            },
            cancelSubscriptionPopup() {
                usePopupStore().showPopup('confirm', {
                    title: 'popups.confirm_subscription_delete.title',
                    description: this.subscription.paid_to_time ? 'popups.confirm_subscription_delete.description' : 'popups.confirm_subscription_delete.description_alt',
                    btnConfirm: "popups.confirm_subscription_delete.button_confirm",
                    btnCancel: "popups.confirm_subscription_delete.button_cancel",
                    confirmFunction: this.deleteSubscription,
                    danger: true,
                    translationPlaceholders: {
                        description: {
                            renewalDate: this.subscription.paid_to_time ? timestampToDisplayDate(this.subscription.paid_to_time, false) : '[?]',
                        }
                    }
                });
            },
            async deleteSubscription() {
                const accountStore = useAccountStore();
                const response = await accountStore.deleteSubscription(this.subscription.order_id);

                if(response) {
                    this.toastStore.addToast(i18n.global.t('toasts.success.subscription_deleted'), 'success');
                } else {
                    this.toastStore.addToast(i18n.global.t('toasts.error.subscription_not_deleted'), 'error');
                }
            }
        },
        mounted() {
            this.fetchSubscriptions();
        }
    }
</script>