<template>
    <div class="users">
        <PartnerUsersHeader />
        <PartnerUsersList />
    </div>
</template>

<script>
    import { useAccountStore } from '@/stores/account';
    import i18nViewMixin from '@/mixins/i18nViewMixin';
    import PartnerUsersHeader from '@/components/admin/partnerAdmins/PartnerUsersHeader';
    import PartnerUsersList from '@/components/admin/partnerAdmins/PartnerUsersList';

    export default {
        name: 'PartnerUsersView',
        mixins: [i18nViewMixin],
        data() {
            return {
                seo_title: 'views.partner_users.seo_title'
            };
        },
        components: {
            PartnerUsersHeader,
            PartnerUsersList
        },
        computed: {
            accountStore() {
                return useAccountStore();
            }
        },
        created() {
            this.accountStore.resetAccount();
        }
    }
</script>