<template>
    <form class="coupon-type-1 form-type-0" :class="isValid ? 'valid' : isInvalid ? 'invalid' : ''" @submit.prevent="validateCoupon">
        <CustomInput
            v-model="couponCode"
            inputKey="coupon_subscriptions"
            @update:modelValue="onChangedDebounced"
        />
        <div v-if="loading && couponCode?.length > 4" class="loading-spinner-type-1 primary"></div>
        <div class="notice success" v-if="isValid">{{ $t('fields.coupon_subscriptions.success', {coupon: coupon.coupon, discount: formatDiscount(coupon.discount)}) }}</div>
        <div class="notice error" v-else-if="isInvalid">{{ $t('fields.coupon_subscriptions.error', {coupon: couponCode}) }}</div>
    </form>
</template>

<script>
    import debounce from 'lodash/debounce';
    import { validateCoupon } from '@/services/ppApi';
    import CustomInput from '@/components/elements/inputs/CustomInput';

    export default {
        name: 'InvoiceDetails',
        emits: ['newCoupon'],
        components: {
            CustomInput
        },
        props: {
            coupon: {
                type: Object,
                default: () => ({
                    coupon: ''
                })
            }
        },
        data() {
            return {
                couponCode: '',
                valid: false,
                loading: false
            }
        },
        computed: {
            isValid() {
                return this.coupon?.coupon && this.coupon?.discount;
            },
            isInvalid() {
                return !this.isValid && !this.loading && this.couponCode?.length > 4;
            }
        },
        methods: {
            formatDiscount(discount) {
                const number = parseFloat(discount);

                if (isNaN(number)) {
                    return discount;
                }

                return number.toFixed(2) + '%';
            },
            async validateCoupon() {
                if(this.couponCode.length < 5) {
                    this.$emit('newCoupon', {});
                    return;
                }

                this.loading = true;
                
                try {
                    const response = await validateCoupon(this.couponCode);

                    if(response?.data?.found > 0) {
                        this.$emit('newCoupon', response.data.coupons[0]);
                        this.valid = true;
                    } else {
                        this.$emit('newCoupon', {});
                        this.valid = false;
                    }
                } catch {
                    this.$emit('newCoupon', {});
                    this.valid = false;
                } finally {
                    this.loading = false;
                }
            },
            onChangedDebounced(value) {
                this.couponCode = value;
                this.valid = false;
                this.loading = true;
                this.updateDebounced();
            },
            updateDebounced: debounce(function() {
                this.validateCoupon();
            }, 500)
        },
        mounted() {
            this.couponCode = this.coupon?.coupon || '';
        }
    }
</script>