<template>
    <AccountViewBase :account="account" :loading="loading" :error="error">
        <div class="container-fluid content-type-3">
            <div class="container">
                <SubscriptionsTitle />
                <SubscriptionSection />
                <InvoiceDetailsSection />
                <InvoiceSection />
            </div>
        </div>
    </AccountViewBase>
</template>

<script>
    import AccountViewBase from '@/views/account/AccountViewBase';
    import i18nViewMixin from '@/mixins/i18nViewMixin';
    import accountMixin from '@/mixins/accountMixin';
    import SubscriptionsTitle from '@/components/account/subscriptions/SubscriptionsTitle';
    import SubscriptionSection from '@/components/account/subscriptions/SubscriptionSettings';
    import InvoiceDetailsSection from '@/components/account/subscriptions/InvoiceDetails';
    import InvoiceSection from '@/components/account/subscriptions/InvoiceDocuments';
    import { scrollToTop } from '@/utils/scroll';

    export default {
        name: 'SubscriptionsView',
        components: {
            AccountViewBase,
            SubscriptionsTitle,
            SubscriptionSection,
            InvoiceDetailsSection,
            InvoiceSection
        },
        mixins: [i18nViewMixin, accountMixin],
        data() {
            return {
                seo_title: 'views.account_subscriptions.seo_title',
            };
        },
        mounted() {
            scrollToTop();
        }
    }
</script>
