<template>
    <div :class="['custom-radio', cssClass]">
        <label v-if="showLabel && $te(label)" :for="uniqueId" :class="['input-label', { required: required }]">{{ $t(label) }}</label>
        <ul :ref="inputKey">
            <li v-if="allowEmpty" class="custom-radio-option hidden-up">
                <input
                    type="radio"
                    :id="`${uniqueId}-empty`"
                    :name="inputKey"
                    value=""
                    :checked="modelValue === ''"
                    @change="onInput"
                />
            </li>
            <li v-for="option in options" :key="option.value" :class="['custom-radio-option', hideNonSelected && modelValue !== option.value ? 'hidden-up' : '' ]">
                <input
                    :key="`${uniqueId}-${option.value}-${modelValue}`"
                    type="radio"
                    :id="`${uniqueId}-${option.value}`"
                    :name="inputKey"
                    :value="option.value"
                    :checked="modelValue === option.value"
                    :disabled="hideNonSelected || paywallActive"
                    :required="required && !allowEmpty"
                    @change="onInput"
                />
                <label :for="`${uniqueId}-${option.value}`" :class="['option-label', modelValue === option.value ? 'selected' : 'not-selected']" @click="onClick(option.value)">
                    <div class="custom-radio-icon" v-if="!hideNonSelected"></div>
                    <div class="custom-radio-image" v-if="option.image">
                        <div :class="[`icon-${option.image}-pos`]"></div>
                    </div>

                    <div v-if="(option.description || option.bullets) && option.price" class="right">
                        <div class="custom-radio-text" v-html="$t(option.text)"></div>
                        <div class="custom-radio-price" v-if="option.price?.label">{{$t(option.price.label, {value: option.price.value})}}</div>
                        <div class="custom-radio-description" v-if="option.description" v-html="$t(option.description)"></div>
                        <ul class="custom-radio-bullets list-checklist" v-if="option.bullets">
                            <li v-for="(bullet, index) in option.bullets" :key="index" :class="[bullet.class || '']">{{$t(bullet.text)}}</li>
                        </ul>
                        <div class="custom-radio-notice" v-if="option.notice" :class="[option.notice.css || '']">{{$t( option.notice.label, option.notice.translationLabels )}}</div>
                    </div>
                    <div v-else class="custom-radio-text" v-html="$t(option.text)"></div>
                </label>
            </li>
        </ul>
        <p v-if="inputError" class="input-error">{{ $te(error) ? $t(error) : $t('fields.default.radio.error') }}</p>
    </div>
</template>

<script>
import { nextTick } from 'vue';

export default {
    props: {
        inputKey: String,
        modelValue: [String, Number],
        required: {
            type: Boolean,
            default: false,
        },
        options: {
            type: Array,
            required: true
        },
        showLabel: {
            type: Boolean,
            default: true,
        },
        cssClass: {
            type: String,
            required: false
        },
        allowEmpty: {
            type: Boolean,
            default: false
        },
        toggleReset: {
            type: Boolean,
            default: false
        },
        hideNonSelected: {
            type: Boolean,
            default: false
        },
        paywallActive: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            inputError: false,
            label: `fields.${this.inputKey}.label`,
            error: `fields.${this.inputKey}.error`,
            uniqueId: `${this.inputKey}-${Math.random().toString(36).substr(2, 9)}`
        };
    },
    methods: {
        onInput(event) {
            this.$emit('update:modelValue', event.target.value);
            nextTick(() => {
                this.validateInput(event.target.value);
            });
        },
        onClick(value) {
            if(this.toggleReset && this.allowEmpty && value === this.modelValue) {
                setTimeout(() => {
                    this.$emit('update:modelValue', '');

                    nextTick(() => {
                        this.validateInput('');
                    });
                }, 100);
            }
        },
        validateInput(value) {
            const isValidOption = this.options.some(option => option.value === value);
            const input = this.$refs[this.inputKey];

            if (this.required && !this.allowEmpty && (!value || !isValidOption)) {
                this.inputError = true;
                input.classList.add('is-invalid');
                input.classList.remove('is-valid');
                return false;
            } else {
                this.inputError = false;
                input.classList.remove('is-invalid');
                input.classList.add('is-valid');
                return true;
            }
        },
        triggerValidation() {
            return this.validateInput(this.modelValue);
        }
    }
};
</script>
