import { createRouter, createWebHistory } from 'vue-router';
import {
    ifNotAuthenticatedRedirect,
    ifAuthenticatedRedirect,
    hasQueryParamPasswordSelect,
    hasAccountId,
    hasPartnerAdminRights,
    hasAccountAdminRights,
    accountHasSubscription,
    accountHasNoSubscription,
    hasQueryParamsOrder,
    hasPaywall } from '@/router/guards';

import CompanySearchView from '@/views/account/CompanySearchView';
import CompanySearchResultView from '@/views/account/CompanySearchResultView';
import AccountsView from '@/views/AccountsView';
import ProfileView from '@/views/ProfileView';
import AccountSubscriptionsView from '@/views/account/SubscriptionsView';
import AccountOrderView from '@/views/account/SubscriptionOrderView';
import AccountChangeSubscriptionView from '@/views/account/SubscriptionChangeView';
import AccountSettingsView from '@/views/account/SettingsView';
import AccountOnboardingView from '@/views/account/OnboardingView';
import AccountUsersView from '@/views/account/UsersView';
import SuspectView from '@/views/account/SuspectView';
import ProspectsView from '@/views/account/ProspectsView';
import ProspectView from '@/views/account/ProspectView';
import LoginView from '@/views/authentication/LoginView';
import PasswordResetView from '@/views/authentication/PasswordResetView';
import PasswordSelectView from '@/views/authentication/PasswordSelectView';
import RegisterView from '@/views/authentication/RegisterView';
import PartnerUsersView from '@/views/admin/PartnerUsersView';
import PaymentSuccessView from '@/views/account/PaymentSuccessView';
import PaymentFailedView from '@/views/account/PaymentFailedView';

const routes = [
    {
        path: '/',
        name: 'home',
        component: ProspectsView,
        beforeEnter: [ifNotAuthenticatedRedirect, ifAuthenticatedRedirect] // This redirects all visitors to either signin, accounts or prospects
    },
    {
        path: '/search',
        name: 'search',
        component: CompanySearchView,
        beforeEnter: [ifNotAuthenticatedRedirect, hasAccountId]
    },
    {
        path: '/search/:id',
        name: 'search-result',
        component: CompanySearchResultView,
        props: true,
        beforeEnter: [ifNotAuthenticatedRedirect, hasAccountId]
    },
    {
        path: '/onboarding',
        name: 'onboarding',
        component: AccountOnboardingView,
        beforeEnter: [ifNotAuthenticatedRedirect, hasAccountId],
    },
    {
        path: '/settings',
        name: 'settings',
        component: AccountSettingsView,
        beforeEnter: [ifNotAuthenticatedRedirect, hasAccountId, hasPaywall],
    },
    {
        path: '/subscriptions',
        name: 'subscriptions',
        component: AccountSubscriptionsView,
        beforeEnter: [ifNotAuthenticatedRedirect, hasAccountId, hasAccountAdminRights],
    },
    {
        path: '/subscriptions/order',
        name: 'subscription-order',
        component: AccountOrderView,
        beforeEnter: [ifNotAuthenticatedRedirect, hasAccountId, hasAccountAdminRights, accountHasNoSubscription],
    },
    {
        path: '/subscriptions/change',
        name: 'subscription-change',
        component: AccountChangeSubscriptionView,
        beforeEnter: [ifNotAuthenticatedRedirect, hasAccountId, hasAccountAdminRights, accountHasSubscription],
    },
    {
        path: '/subscriptions/payment-success',
        name: 'payment-success',
        component: PaymentSuccessView,
        beforeEnter: [ifNotAuthenticatedRedirect, hasQueryParamsOrder],
        props: (route) => ({ account_id: route.query.account_id, ordeer_id: route.query.order_id })
    },
    {
        path: '/subscriptions/payment-failed',
        name: 'payment-failed',
        component: PaymentFailedView,
        beforeEnter: [ifNotAuthenticatedRedirect, hasQueryParamsOrder],
        props: (route) => ({ account_id: route.query.account_id, ordeer_id: route.query.order_id })
    },
    {
        path: '/suspect',
        name: 'suspect',
        component: SuspectView,
        beforeEnter: [ifNotAuthenticatedRedirect, hasAccountId, hasPaywall],
    },
    {
        path: '/prospects',
        name: 'prospects',
        component: ProspectsView,
        beforeEnter: [ifNotAuthenticatedRedirect, hasAccountId],
    },
    {
        path: '/prospects/:id',
        name: 'prospect',
        component: ProspectView,
        beforeEnter: [ifNotAuthenticatedRedirect, hasAccountId],
        props: true
    },
    {
        path: '/users',
        name: 'users',
        component: AccountUsersView,
        beforeEnter: [ifNotAuthenticatedRedirect, hasAccountId, hasAccountAdminRights, hasPaywall],
    },
    {
        path: '/admins',
        name: 'admins',
        component: PartnerUsersView,
        beforeEnter: [ifNotAuthenticatedRedirect, hasPartnerAdminRights],
    },
    {
        path: '/accounts',
        name: 'accounts',
        component: AccountsView,
        beforeEnter: ifNotAuthenticatedRedirect
    },
    {
        path: '/profile',
        name: 'profile',
        component: ProfileView,
        beforeEnter: ifNotAuthenticatedRedirect
    },
    {
        path: '/login',
        name: 'login',
        component: LoginView,
        beforeEnter: ifAuthenticatedRedirect
    },
    {
        path: '/register',
        name: 'register',
        component: RegisterView,
        beforeEnter: ifAuthenticatedRedirect
    },
    {
        path: '/reset-password',
        name: 'passwordReset',
        component: PasswordResetView,
        beforeEnter: ifAuthenticatedRedirect
    },
    {
        path: '/select-password',
        name: 'passwordSelect',
        component: PasswordSelectView,
        beforeEnter: [ifAuthenticatedRedirect, hasQueryParamPasswordSelect],
        props: (route) => ({ r: route.query.r })
    },
    {
        path: '/:catchAll(.*)',
        redirect: '/'
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
