<template>
    <AccountViewBase :account="account" :loading="loading" :error="error">
        <SearchDetails />
    </AccountViewBase>
</template>

<script>
    import { mapState, mapActions } from 'pinia';
    import AccountViewBase from '@/views/account/AccountViewBase';
    import i18nViewMixin from '@/mixins/i18nViewMixin';
    import accountMixin from '@/mixins/accountMixin';
    import { useProspectStore } from '@/stores/prospect';
    import { useCompanyStore } from '@/stores/company';
    import { scrollToTop } from '@/utils/scroll';
    import SearchDetails from '@/components/account/search/SearchDetails';

    export default {
        name: 'CompanySearchResultView',
        props: {
            id: {
                type: String,
                required: true
            }
        },
        components: {
            AccountViewBase,
            SearchDetails
        },
        mixins: [i18nViewMixin, accountMixin],
        data() {
            return {
                seo_title: 'views.prospects.seo_title'
            };
        },
        computed: {
            ...mapState(useProspectStore, ['prospect']),
            ...mapState(useCompanyStore, ['loading', 'error'])
        },
        methods: {
            ...mapActions(useCompanyStore, ['fetchCompany', 'resetCompany']),
            ...mapActions(useProspectStore, ['resetProspect']),
        },
        watch: {
            '$route.params.id': {
                handler(newId) {
                    if(!this.loading) {
                        scrollToTop();
                        this.fetchCompany(newId, this.$router);
                    }
                },
                immediate: true
            }
        },
        mounted() {
            scrollToTop();
            
            if(this.id) {
                if(!this.loading) {
                    this.fetchCompany(this.id, this.$router);
                }
            } else {
                this.$router.push({ name: 'search' });
            }
        },
        unmounted() {
            this.resetProspect();
            this.resetCompany();
        }
    }
</script>
