<template>
    <div :class="['custom-range', 'custom-range-type-1', cssClass]">
        <label v-if="showLabel && $te(label)" :for="uniqueId" class="input-label">{{ $t(label) }}</label>
        <div class="range-inputs">
            <CustomInput
                :modelValue="minValue"
                :inputKey="`${inputKey}.min`"
                @update:modelValue="onMinInput"
                type='number'
                pattern="^[1-9]\d*$"
                :min="min"
                :max="max"
                :step="step"
                :showLabel="showLabelMinMax"
                :showError="false"
            />
            <CustomInput
                :modelValue="maxValue"
                :inputKey="`${inputKey}.max`"
                @update:modelValue="onMaxInput"
                type='number'
                pattern="^[1-9]\d*$"
                :min="min"
                :max="max"
                :step="step"
                :showLabel="showLabelMinMax"
                :showError="false"
            />
        </div>
        <div class="range-slider">
            <div
                class="range-highlight"
                :style="highlightStyle"
            ></div>
            <input
                type="range"
                :min="min"
                :max="max"
                :step="step"
                :value="minValue"
                @input="onMinRangeInput"
                class="range-bar"
                ref="minRangeInput"
            />
            <input
                type="range"
                :min="min"
                :max="max"
                :step="step"
                :value="maxValue"
                @input="onMaxRangeInput"
                class="range-bar"
                ref="maxRangeInput"
            />
        </div>
        <div class="range-limits">
            <div class="min">{{ $te(`fields.${this.inputKey}.min.label`) ? $t(`fields.${this.inputKey}.min.axis`, formatLabelRange ? { min: formatNumber(min) } : {min}) : min }}</div>
            <div class="max">{{ $te(`fields.${this.inputKey}.max.label`) ? $t(`fields.${this.inputKey}.max.axis`, formatLabelRange ? { max: formatNumber(max) } : {max}) : max }}</div>
        </div>
        <p v-if="inputError" class="input-error">{{ $te(error) ? $t(error) : $t('fields.default.range.error') }}</p>
    </div>
</template>

<script>
import { nextTick } from 'vue';
import CustomInput from '@/components/elements/inputs/CustomInput';
import { formatNumber } from '@/utils/formatting';

export default {
    components: {
        CustomInput
    },
    props: {
        inputKey: String,
        modelValue: {
            type: Object,
            default: () => ({ min: this.min, max: this.max })
        },
        min: {
            type: Number,
            default: 0
        },
        max: {
            type: Number,
            default: 100
        },
        step: {
            type: Number,
            default: 1
        },
        cssClass: {
            type: String,
            required: false
        },
        required: {
            type: Boolean,
            default: false,
        },
        showLabel: {
            type: Boolean,
            default: true,
        },
        showLabelMinMax: {
            type: Boolean,
            default: false
        },
        formatLabelRange: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            inputError: false,
            label: `fields.${this.inputKey}.label`,
            error: `fields.${this.inputKey}.error`,
            uniqueId: `${this.inputKey}-${Math.random().toString(36).substr(2, 9)}`,
            minValue: this.modelValue.min,
            maxValue: this.modelValue.max && this.modelValue.max <= this.max ? this.modelValue.max : this.max
        };
    },
    computed: {
        highlightStyle() {
            const thumbSize = '1rem';
            var minPerc = ((this.minValue - this.min) / (this.max - this.min)) * 100;
            var maxPerc = ((this.maxValue - this.min) / (this.max - this.min)) * 100;
            var left;
            var width;

            if(minPerc > 50) {
                left = `calc(${minPerc}% - (${minPerc} * ${thumbSize} / 100))`;
                width = `calc(${maxPerc - minPerc}% + (${minPerc} * ${thumbSize} / 100))`;
            } else if(maxPerc < 50) {
                left = `${minPerc}%`;
                width = `calc(${maxPerc - minPerc}% + (${100 - maxPerc} * ${thumbSize} / 100))`;
            } else {
                left = `${minPerc}%`;
                width = `${maxPerc - minPerc}%`;
            }

            return {
                left, width
            };
        }
    },
    methods: {
        formatNumber,
        onMinInput(value) {
            this.minValue = Number(value);
            this.updateModelValue();
        },
        onMaxInput(value) {
            this.maxValue = Number(value);
            this.updateModelValue();
        },
        onMinRangeInput(event) {
            this.minValue = Math.min(Number(event.target.value), this.maxValue - this.step);
            this.forceUpdateRangeInputs();
            this.updateModelValue();
        },
        onMaxRangeInput(event) {
            this.maxValue = Math.max(Number(event.target.value), this.minValue + this.step);
            this.forceUpdateRangeInputs();
            this.updateModelValue();
        },
        updateModelValue() {
            this.$emit('update:modelValue', { min: this.minValue, max: this.maxValue });
            nextTick(() => {
                this.validateInput();
            });
        },
        validateInput() {
            if (this.minValue > this.maxValue || (this.required && (this.minValue === this.min || this.maxValue === this.max))) {
                this.inputError = true;
            } else {
                this.inputError = false;
            }
            return !this.inputError;
        },
        triggerValidation() {
            return this.validateInput();
        },
        forceUpdateRangeInputs() {
            this.$refs.minRangeInput.value = this.minValue;
            this.$refs.maxRangeInput.value = this.maxValue;
        },
    },
    watch: {
        modelValue: {
            immediate: true,
            handler(newValue) {
                this.minValue = newValue.min || this.min;
                this.maxValue = newValue.max && newValue.max <= this.max ? newValue.max : this.max;
            }
        },
        minValue(value) {
            if (value >= this.maxValue) {
                this.minValue = this.maxValue - this.step;
            }
        },
        maxValue(value) {
            if (value <= this.minValue) {
                this.maxValue = this.minValue + this.step;
            }
        }
    },
};
</script>

