import router from '@/router';
import { defineStore } from 'pinia';
import { fetchSuspects } from '@/services/ppApi';
import { useAccountStore } from '@/stores/account';
import { useUserStore } from '@/stores/user';
import { useProspectStore } from '@/stores/prospect';
import { prefetchManager } from '@/services/ppPrefetchManager';

export const useSuspectStore = defineStore('suspect', {
    state: () => {
        const accountStore = useAccountStore();
        const accountId = accountStore?.account?.account_id ? accountStore.account.account_id : null;
        const userStore = useUserStore();
        const userSuspectSettings = userStore.getSuspectSettings(accountId || null);

        const defaultOptions = {
            search: '',
            socials: [],
            orgtype: [],
            location: {
                province: [],
                city: '',
                radius: null,
            },
            apps: [],
            employees: '',
            revenue: '',
            reviews: '',
            founded: '',
            queryTotal: null
        };

        return {
            currentParams: userSuspectSettings?.currentParams || {},
            nextParams: userSuspectSettings?.nextParams || {},
            history: userSuspectSettings?.history || [],
            loading: false,
            error: null,
            total: 0,
            defaultOptions: defaultOptions,
            options: userSuspectSettings?.options ? { ...defaultOptions, ...userSuspectSettings.options } : { ...defaultOptions },
            optionsChanged: false
        }
    },
    getters: {
        hasDefaultOptions(state) {
            return JSON.stringify(state.options) === JSON.stringify(state.defaultOptions);
        }
    },
    actions: {
        async fetchSuspect(params = {}) {
            this.loading = true;

            prefetchManager.clearQueue();

            if(!params || Object.keys(params).length < 1) {
                params = this.generateQueryParams();
            }

            try {
                const accountStore = useAccountStore();
                const response = await fetchSuspects({ 
                    ...params,
                    add_details: '*',
                    account_id: accountStore.account.account_id,
                    add_fields: 'owner',
                    prefetch: 1
                });
                
                if(response?.data?.found > 0) {
                    this.total = response.data.total || response.data.found;
                    useProspectStore().setProspect(response.data.companies[0]);
                    this.currentParams = params;

                    const newInHistory = this.addToHistory({
                        type: 'prospect',
                        id: useProspectStore().prospect.id
                    });

                    if(newInHistory) {
                        const url = new URL(response.data.next);
                        this.nextParams = Object.fromEntries(url.searchParams.entries());
                    }

                    if(!this.hasDefaultOptions && this.total && this.total > 1) {
                        this.queryTotal = this.total;
                    }

                    const prefetchLinks = response.data.prefetch_links;
                    if (Array.isArray(prefetchLinks) && prefetchLinks.length > 0) {
                        prefetchManager.enqueue(prefetchLinks);
                    }

                    this.saveUserSuspectSettings();
                } else {
                    this.total = 0;
                    useProspectStore().resetProspect();
                    this.saveUserSuspectSettings();
                }
            } catch(error) {
                if(error?.response?.data?.error_code === 'no_data') {
                    this.total = 0;
                    useProspectStore().resetProspect();
                    this.saveUserSuspectSettings();
                    return;
                }

                useProspectStore().resetProspect();
                this.error = error;

                // Handle inactive subscriptions etc. here.
                this.saveUserSuspectSettings(true);
                router.push({ name: 'prospects' });
            } finally {
                this.loading = false;
                this.optionsChanged = false;
            }
        },
        generateQueryParams() {
            var location = {};
            this.history = [];
            this.queryTotal = null;

            if(this.options.location.province.length > 0) {
                location = { province: this.options.location.province.join(',') }
            } else if (this.options.location.city !== '' && this.options.location.radius !== null && this.options.location.radius !== '0') {
                location = {
                    location: this.options.location.city,
                    km: this.options.location.radius
                }
            } else if (this.options.location.city !== '') {
                location = { city: this.options.location.city }
            }

            return {
                ...(this.options.search && {text: this.options.search}),
                ...(this.options.employees && {employees: this.options.employees}),
                ...(this.options.revenue && {revenue: this.options.revenue}),
                ...(this.options.reviews && {revenue: this.options.reviews}),
                ...(this.options.founded && {founded: this.options.founded}),
                ...(this.options.socials && this.options.socials.length > 0 && {social_exists: this.options.socials.join(',')}),
                ...(this.options.orgtype && this.options.orgtype.length > 0 && {orgtype: this.options.orgtype.join(',')}),
                ...(this.options.apps && this.options.apps.length > 0 && {apps: this.options.apps.map(obj => obj.value).join(',')}),
                ...location
            };
        },
        async qualifySuspect(label) {
            const prospectStore = useProspectStore();
            if(label !== -1 || (label === -1 && prospectStore.prospect.label !== 0 && prospectStore.prospect.label !== 1)) {
                await prospectStore.updateLabel(label);
            }
            this.nextSuspect();
        },
        nextInHistory(id) {
            if(this.history?.length > 0) {
                let index = this.history.findIndex(item => item.id === id);
        
                if(index !== -1 && (index + 1) < this.history.length) {
                    return this.history[index + 1];
                }
            }
            return false;
        },
        previousInHistory(id) {
            if(this.history?.length > 0) {
                let index = this.history.findIndex(item => item.id === id);
        
                if(index > 0) {
                    return this.history[index - 1];
                }
            }
            return false;
        },
        addToHistory(newItem) {
            let index = this.history.findIndex(item => item.id === newItem.id);
            if(index === -1) {
                this.history.push(newItem);
                return true;
            }
            return false;
        },

        async fetchFirstSuspect() {
            this.fetchSuspect(this.currentParams && Object.keys(this.currentParams).length > 0 ? this.currentParams : undefined);
        },
        async nextSuspect(forceNext = false) {
            const prospectStore = useProspectStore();
        
            if(!prospectStore.prospect) {
                await this.fetchFirstSuspect();
            } else {
                const nextItem = this.nextInHistory(prospectStore.prospect.id);
                if(nextItem && !forceNext) {
                    await this.fetchHistoryItem(nextItem);
                } else {
                    await this.fetchSuspect(this.nextParams && Object.keys(this.nextParams).length > 0 ? this.nextParams : undefined);
                }
            }
        },
        async previousSuspect() {
            const prospectStore = useProspectStore();

            if(!prospectStore.prospect) {
                this.fetchFirstSuspect();
            } if(this.previousInHistory(prospectStore.prospect.id)) {
                const item = this.previousInHistory(prospectStore.prospect.id);
                this.fetchHistoryItem(item);
            } else {
                this.fetchSuspect();
            }
        },
        async fetchHistoryItem(item = undefined) {
            if(item?.type && item.type === 'prospect') {
                this.loading = true;
                
                try {
                    const prospectStore = useProspectStore();
                    const success = await prospectStore.fetchProspect(item.id);

                    if(success) {
                        this.addToHistory({
                            type: 'prospect',
                            id: useProspectStore().prospect.id
                        });
                        this.saveUserSuspectSettings();
                    } else {
                        this.fetchSuspect();
                    }
                } catch {
                    this.fetchSuspect();
                } finally {
                    this.loading = false;
                }
            } else if (item?.type && item.type === 'suspect' && item.params) {
                this.fetchSuspect(item.params);
            } else {
                this.fetchSuspect();
            }
        },
        resetSuspect() {
            this.$reset();
        },
        updateOptions(newOptions) {
            this.options = { ...this.options, ...newOptions };
            this.nextParams = {};
            this.currentParams = {};
            this.optionsChanged = true;
        },
        resetOptions() {
            this.options = { ...this.defaultOptions };
            this.updateOptions(this.options);
            this.fetchSuspect();
        },
        saveUserSuspectSettings(deleteSettings = false) {
            const userStore = useUserStore();
            const accountStore = useAccountStore();

            userStore.saveSuspectSettings(
                accountStore.account.account_id,
                deleteSettings ? null : {
                    options: this.options,
                    nextParams: this.nextParams,
                    currentParams: this.currentParams,
                    history: this.history
                }
            );
        }
    }
});