import { defineStore } from 'pinia';
import { useScreenWidthStore } from '@/stores/utils/screenWidth';

export const useToastStore = defineStore('toast', {
    state: () => ({
        toasts: []
    }),
    actions: {
        addToast(message, type = 'success', path = null, duration = 3000) {
            const toast = { message, type, path, id: Math.random().toString(36).substr(2, 9) };
            const maxToasts = useScreenWidthStore().hasLargeScreen ? 3 : 0;

            if (this.toasts.length >= maxToasts) {
                this.toasts.shift();
            }

            this.toasts.push(toast);

            setTimeout(() => {
                this.removeToast(toast.id);
            }, duration);
        },
        removeToast(id) {
            this.toasts = this.toasts.filter(toast => toast.id !== id);
        },
        removeAllToasts() {
            this.toasts = [];
        }
    }
});
