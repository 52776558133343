<template>
    <img v-if="imageExists && (app != '' || url != '')" class="app-logo" :src="url || imageApiUrl" @error="onError" />
</template>

<script>

export default {
  name: 'AppLogo',
  props: {
    app: {
        type: String,
        default: '-',    
    },
    url: {
        type: String,
        default: '',    
    },
    size: {
      type: Number,
      default: 128
    }
  },
  data() {
    return {
      imageExists: true
    };
  },
  computed: {
        imageApiUrl() {
            return `https://img.prospectpro.nl/img.php?app=${this.app}&empty=${this.size}x${this.size}&min_width=32&l=7`;
        }
  },
  methods: {
    onError() {
      this.imageExists = false;
    }
  }
};
</script>