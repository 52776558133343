<template>
    <div class="container-fluid content-type-23">
        <div class="container">
            <div class="row row-steps">
                <div class="col-12 col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2">
                    <ProcessSteps :steps="steps" @navigateToStep="navigateToStep" />
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid content-type-22">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-10 col-md-offset-1 col-xl-8 col-xl-offset-2">
                    <div class="page-title">
                        <h1>{{ currentStep ? $t(`views.account_order.steps.${currentStep.key}.title`) : '' }}</h1>
                        <p>{{ currentStep ? $t(`views.account_order.steps.${currentStep.key}.description`) : '' }}</p>
                    </div>
                </div>
            </div>

            <div class="row" v-if="step === 1">
                <div class="col-12 col-sm-10 col-sm-offset-1 col-lg-12 col-lg-offset-0 col-xl-10 col-xl-offset-1">
                    <div class="content">
                        <SubscriptionForm />
                    </div>
                </div>
            </div>

            <div class="row" v-else-if="step === 2">
                <div class="col-12 col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2 col-lg- col-lg-offset- col-xl-6 col-xl-offset-3">
                    <div class="content">
                        <InvoiceDetailsForm :hideConsent="false" :hideVat="true" :tcOnly="true" @saved="setInvoiceDetails" @hasChanged="updateInvoiceDetailsModified" :hideSaveButton="accountStore.hasInvoiceAddress ? true : false" :initValidation="true" />
                        <div class="btn-1 btn-block btn-m" v-if="!invoiceDetailsModified && accountStore.hasInvoiceAddress" @click="navigateToStep(3)">
                            <span>{{ $t('views.account_order.steps.invoice_details.button_skip') }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row row-checkout" v-else-if="step === 3">
                <OrderCheckout />
            </div>
        </div>
    </div>
</template>

<script>
    import { useAccountStore } from '@/stores/account';
    import { useOrderStore } from '@/stores/order';
    import { mapState, mapActions } from 'pinia';
    import ProcessSteps from '@/components/elements/misc/ProcessSteps';
    import OrderCheckout from '@/components/account/subscriptions/order/OrderCheckout';
    import InvoiceDetailsForm from '@/components/forms/InvoiceDetailsForm';
    import SubscriptionForm from '@/components/forms/SubscriptionForm';
    import { scrollToTop } from '@/utils/scroll';
    import { nextTick } from 'vue';
    
    export default {
        name: 'InvoiceSettings',
        components: {
            ProcessSteps,
            OrderCheckout,
            InvoiceDetailsForm,
            SubscriptionForm
        },
        computed: {
            ...mapState(useOrderStore, ['step', 'steps', 'currentStep', 'invoiceDetailsModified']),
            accountStore() {
                return useAccountStore();
            },
            orderStore() {
                return useOrderStore();
            },
        },
        methods: {
            ...mapActions(useOrderStore, ['navigateToStep', 'setInvoiceDetails', 'updateInvoiceDetailsModified']),
        },
        watch: {
            step(step) {
                if(step > 0) {
                    nextTick(() => {
                        scrollToTop();
                    });
                }
            }
        }
    }
</script>