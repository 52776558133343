<template>
    <section class="container-fluid header-list-type-2">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="wrp">
                        <div class="content">
                            <h1 class="name">{{ $t('views.users.title') }}</h1>
                            <p>{{ $t('views.users.description', { accountName: accountStore.account.name }) }}</p>
                        </div>
                        <div class="actions">
                            <UserCreate />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import UserCreate from '@/components/account/users/UserCreate';
    import { useAccountStore } from '@/stores/account';

    export default {
        name: "UserHeader",
        components: {
            UserCreate
        },
        computed: {
            accountStore() {
                return useAccountStore();
            }
        }
    }
</script>