export default {
    data() {
        return {
            formError: false,
            formSuccess: false,
            loading: false,
            hasChanged: false,
            isValid: null
        };
    },
    methods: {
        onInputChange() {
            this.hasChanged = true;

            if (this.formError) {
                this.formError = false;
            }

            if (this.formSuccess) {
                this.formSuccess = false;
            }
        },
        validateAllFields() {
            let allValid = true;
            Object.keys(this.$refs).forEach(ref => {
                if (this.$refs[ref] && typeof this.$refs[ref].triggerValidation === 'function') {
                    const isValid = this.$refs[ref].triggerValidation();
                    if (!isValid) {
                        allValid = false;
                    }
                }
            });

            this.isValid = allValid;
            return allValid;
        }
    }
};
  