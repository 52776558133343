<template>
    <WidgetSection
        ref="WidgetSection"
        :loaded="loaded"
        :loading="loading"
        :defaultToggle="defaultToggle"
        v-if="!hide"
        @loading-start="handleLoadingStart"
        loadingText="components.prospect_pageviews.loading_text"
        :paywall="paywall"
    >
        <template v-slot:section-title>
            <div class="title">
                {{ $t('components.prospect_pageviews.name')}}
            </div>
        </template>

        <template v-slot:section-content>
            <div class="row row-content">
                <div class="table-container">
                    <table class="table-type-1">
                        <thead>
                            <tr class="table-header">
                                <td>{{ $t('common.page_title') }}</td>
                                <td>{{ $t('common.last_visit') }}</td>
                                <td>{{ $t('common.language') }}</td>
                                <td>{{ $t('common.medium') }}</td>
                                <td>{{ $t('common.source') }}</td>
                                <td>{{ $t('common.device_type') }}</td>
                                <td>{{ $t('common.platform') }}</td>
                                <td>{{ $t('common.browser') }}</td>
                                <td>{{ $t('common.referer') }}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="company" v-for="pageview in pageviews" :key="pageview.last_visit">
                                <td class="pageview">
                                    <a :href="pageview.page_url.split('?')[0]" target="_blank" rel="noreferrer external">{{ pageview.page_title }}</a>
                                </td>
                                <td>{{ formatDateReverse(pageview.last_visit_date) }}</td>
                                <td>{{ pageSource?.language ? pageSource.language : '-' }}</td>
                                <td>{{ pageSource?.medium ? pageSource.medium : '-' }}</td>
                                <td>{{ pageSource?.source ? pageSource.source : '-' }}</td>
                                <td>{{ pageSource?.device_type ? pageSource.device_type : '-' }}</td>
                                <td>{{ pageSource?.platform ? pageSource.platform : '-' }}</td>
                                <td>{{ pageSource?.browser ? pageSource.browser : '-' }}</td>
                                <td>{{ pageSource?.referer ? pageSource.referer : '-' }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </template>
    </WidgetSection>
</template>

<script>
    import { useProspectStore } from '@/stores/prospect';
    import { useAccountStore } from '@/stores/account';
    import { formatDateReverse } from '@/utils/formatting';
    import WidgetSection from '@/components/sections/WidgetSection';

    export default {
        name: "ProspectPageviews",
        components: {
            WidgetSection
        },
        props: {
            auto: {
                type: Boolean,
                default: false
            },
            defaultToggle: {
                type: Boolean,
                default: false
            },
            paywall: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                loading: false,
                loaded: false,
                abortController: null,
            }
        },
        computed: {
            paywallActive() {
                return this.paywall && useAccountStore().paywall;
            },
            hide() {
                return !this.loading && this.loaded && (!this.pageviews || this.pageviews.length < 1);
            },
            pageviews() {
                return useProspectStore().pageviews;
            },
            pageSource() {
                return useProspectStore().page_source;
            }
        },
        methods: {
            formatDateReverse,
            async fetchPageviews() {
                if (this.abortController) {
                    this.abortController.abort();
                }

                this.abortController = new AbortController();

                this.loading = true;
                await useProspectStore().fetchPageviews(this.abortController.signal);
                this.loading = false;
                this.loaded = true;
            },
            async handleLoadingStart() {
                if (!this.paywallActive && !this.loaded) {
                    await this.fetchPageviews();
                    
                    if(!this.hide && this.$refs.WidgetSection) {
                        this.$refs.WidgetSection.toggleSection();
                    }
                }
            }
        },
        mounted() {
            if(!this.paywallActive && !this.loaded && (this.auto || this.defaultToggle)) {
                this.fetchPageviews();
            }
        },
        unmounted() {
            if (this.abortController) {
                this.abortController.abort();
            }
        }
    }
</script>