import { fetchUrl } from '@/services/ppApi';

class PrefetchManager {
    constructor() {
        this.queue = [];
        this.isPrefetching = false;
        this.currentController = null;
    }

    /**
     * Enqueue URLs for prefetching
     * @param {Array<string>} urls 
     */
    enqueue(urls) {
        this.queue.push(...urls);
        this.processQueue();
    }

    /**
     * Clears the prefetch queue and aborts any ongoing prefetch
     */
    clearQueue() {
        this.queue = [];
        if (this.currentController) {
            this.currentController.abort();
            this.currentController = null;
        }
        this.isPrefetching = false;
    }

    /**
     * Processes the prefetch queue
     */
    async processQueue() {
        if (this.isPrefetching || this.queue.length === 0) {
            return;
        }

        // Check for active fetches in the application
        if (window.activeFetches && window.activeFetches > 0) {
            // Retry after a short delay if there are active fetches
            setTimeout(() => this.processQueue(), 100);
            return;
        }

        const url = this.queue.shift();
        if (!url) return;

        this.isPrefetching = true;
        this.currentController = new AbortController();

        try {
            await fetchUrl(url, this.currentController.signal);
        } catch {
            // Do nothing.
        } finally {
            this.isPrefetching = false;
            this.currentController = null;
            this.processQueue(); // Continue with the next URL
        }
    }
}

export const prefetchManager = new PrefetchManager();
