<template>
    <SettingsSection cssClass="account-tracker-settings">
        <template #title>
            <h2>{{ $t('views.account_settings.account_tracker_settings_title') }}</h2>
        </template>

        <template #content>
            <CopyPasteCode :code="account.tracker_script" />

            <div class="btn-s btn-1 validate-tracker-button" :disabled="loading" v-if="!status" @click="validateTracker"><span v-if="!loading">{{$t('components.validateTracker.button_validate')}}</span><div v-if="loading" class="loading-spinner-type-1"></div></div>
            <div class="notice" :class="[status ? 'success' : 'error']" v-if="loaded && !loading">{{$t(status ? 'components.validateTracker.notices.success' : 'components.validateTracker.notices.error')}}</div>
        </template>
    </SettingsSection>
</template>

<script>
    import { useAccountStore } from '@/stores/account';
    import SettingsSection from '@/components/account/settings/Section';
    import CopyPasteCode from '@/components/elements/misc/CopyPasteCode';
    
    export default {
        name: 'AccountTrackerSettings',
        components: {
            SettingsSection,
            CopyPasteCode
        },
        props: {
            account: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                loading: false,
                loaded: false,
                status: false
            }
        },
        methods: {
            async validateTracker() {
                this.loading = true;
                const response = await useAccountStore().validateTracker();                
                this.loading = false;
                this.loaded = true;

                if(response && !response.tracker_installed) {
                    this.status = true;
                    return true;
                } else {
                    this.status = false;
                    return false;
                }
            }
        }
    }
</script>