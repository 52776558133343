export const orgtypeMap = [
    {
      value: "Eenmanszaak",
      name: "Eenmanszaak"
    },
    {
      value: "Besloten Vennootschap",
      name: "Besloten Vennootschap (BV)"
    },
    {
      value: "Naamloze Vennootschap",
      name: "Naamloze Vennootschap (NV)"
    },
    {
      value: "Vennootschap Onder Firma",
      name: "Vennootschap Onder Firma (VOF)"
    },
    {
      value: "Commanditaire Vennootschap",
      name: "Commanditaire Vennootschap (CV)"
    },
    {
      value: "Maatschap",
      name: "Maatschap"
    },
    {
      value: "Coöperatie",
      name: "Coöperatie"
    },
    {
      value: "Stichting",
      name: "Stichting"
    },
    {
      value: "Vereniging",
      name: "Vereniging"
    },
    {
      value: "Buitenlandse Rechtsvorm",
      name: "Buitenlandse Rechtsvorm"
    },
    {
      value: "Publiekrechtelijke Rechtspersoon",
      name: "Publiekrechtelijke Rechtspersoon"
    },
    {
      value: "Rechtspersoon in oprichting",
      name: "Rechtspersoon in oprichting"
    },
    {
      value: "Kerkgenootschap",
      name: "Kerkgenootschap"
    },
    {
      value: "Onderlinge Waarborg Maatschappij",
      name: "Onderlinge Waarborg Maatschappij"
    },
    {
      value: "Rederij",
      name: "Rederij"
    }
];