<template>
    <div class="company-image">
        <img v-if="imageExists && hasDomain" class="company-logo" :src="imageApiUrl" @error="onError" />
        <div v-else class="company-letter">{{ companyFirstLetter }}</div>
    </div>
</template>

<script>

export default {
  name: 'CompanyLogo',
  props: {
    domain: {
        type: String,
        default: '',    
    },
    company_name: {
        type: String,
        default: '-'
    },
    size: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      imageExists: true
    };
  },
  computed: {
        hasDomain() {
            return !!this.domain;
        },
        imageApiUrl() {
            return `https://img.prospectpro.nl/logo.php?domain=${this.domain}&empty=${this.size}x${this.size}&min_width=32&l=7`;
        },
        companyFirstLetter() {
            if(this.company_name) {
                return this.company_name.charAt(0).toUpperCase();
            } else if (this.domain) {
                return this.domain.charAt(0).toUpperCase();
            } else {
                return '-';
            }
        }
  },
  methods: {
    onError() {
      this.imageExists = false;
    }
  }
};
</script>