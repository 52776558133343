<template>
    <WidgetSection
        ref="WidgetSection"
        :loaded="loaded"
        :loading="loading"
        v-if="!hide"
        :defaultToggle="defaultToggle"
        @loading-start="handleLoadingStart"
        loadingText="components.prospect_people.loading_text"
    >
        <template v-slot:section-title>
            <div class="title">
                {{ $t('components.prospect_people.name')}}
            </div>
        </template>

        <template v-slot:section-content>
            <div class="row row-content">
                <div class="table-container table-search">
                    <table class="table-type-1 companies">
                        <thead>
                            <tr class="table-header">
                                <td></td>
                                <td>{{ $t('common.name') }}</td>
                                <td>{{ $t('common.job_title') }}</td>
                                <td>{{ $t('common.email') }}</td>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody v-if="!loadingSearch">
                            <tr class="company" v-for="person in people" :key="person.contact_id">
                                <td class="image">
                                    <ContactImage 
                                        :imgUrl="person.image"
                                        :name_first="person.name_first"
                                        :last_first="person.last_first"
                                    />
                                </td>
                                <td class="company-name">{{ person.name }}</td>
                                <td class="website">{{ person.job_title  || '-' }}</td>
                                <td v-if="!person.email">
                                    <ul class="emails" v-if="person.emails?.length > 0">
                                        <li v-for="(email, index) in person.emails" :key="index" @click="validateEmail(email, person)" :class="showEmailVerification(email) ? 'verification-link' : ''">
                                            <div v-if="email.email" class="email">{{ email.email }}</div>
                                            <div v-if="email.confidence || email.api" class="details">

                                                <div v-if="email.confidence && showEmailVerification(email)" class="confidence">{{ email.confidence }}<span>%</span></div>
                                                
                                                <div v-if="showEmailVerification(email)" class="status">
                                                    <div v-if="loadingEmailValidation.includes(email.email)" class="loading-spinner-type-1 primary"></div>
                                                    <div v-else-if="email.status && (email.status === 'valid' || email.status === 'unknown')" class="indicator-type-1 validate"></div>
                                                    <div v-else-if="email.status === 'invalid'" class="indicator-type-1 invalid"></div>
                                                    <div v-else class="indicator-type-1 valid"></div>
                                                </div>

                                            </div>
                                        </li>
                                    </ul>
                                    <template v-else>-</template>
                                </td>
                                <td v-else class="email">
                                    {{ person.email }} <div v-if="person.emailVerified" class="indicator-type-1 valid" style="display:inline-block;"></div>
                                </td>
                                <td class="actions">
                                    <div>
                                        <a class="btn-xs-icon btn-1" :href="person.linkedin_link" v-if="!paywallActive && person.linkedin_link" target="_blank" rel="noreferrer external">
                                            <div class="icon-linkedin-neg"></div>
                                        </a>
                                        <div class="btn-xs-icon btn-disabled btn-paywall" v-else-if="paywallActive">
                                            <div class="paywall-lock"></div>
                                            <div class="icon-linkedin-neg"></div>
                                        </div>
                                        <div class="btn-1 btn-xs-icon" @click="addContactPopup(person)" v-if="!paywallActive">
                                            <div class="icon-save-neg"></div>
                                        </div>
                                        <div class="btn-xs-icon btn-disabled btn-paywall" v-else>
                                            <div class="paywall-lock"></div>
                                            <div class="icon-save-neg"></div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="loading-table" v-if="loadingSearch">
                        <div class="loading-spinner-type-2"></div>
                    </div>
                    <div class="no-results-table" v-if="!loadingSearch && this.people.length < 1">
                        <div>{{$t('components.prospect_people.search_no_results', {query: this.search})}}</div>
                    </div>
                    <div class="table-search-spacer" v-if="!loadingSearch && this.people.length > 0"></div>
                    <div class="table-footer form-type-0">
                        <CustomInput
                            v-model="search"
                            inputKey="people_search"
                            @update:modelValue="onChangedDebounced"
                        />
                        <div class="btn-icon-s" :class="search ? 'btn-6' : 'btn-disabled'" @click="resetSearch">
                            <div class="icon-reset-neg"></div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </WidgetSection>
</template>

<script>
    import debounce from 'lodash/debounce';
    import { useProspectStore } from '@/stores/prospect';
    import { useAccountStore } from '@/stores/account';
    import WidgetSection from '@/components/sections/WidgetSection';
    import CustomInput from '@/components/elements/inputs/CustomInput';
    import { fetchUrl } from '@/services/ppApi';
    import { markRaw } from 'vue';
    import i18n from "@/plugins/i18n";
    import { usePopupStore } from '@/stores/popup';
    import { useToastStore } from '@/stores/toast';
    import AddContactForm from '@/components/forms/AddContactForm';
    import ContactImage from '@/components/elements/misc/ContactImage';

    export default {
        name: "ProspectPeople",
        components: {
            WidgetSection,
            ContactImage,
            CustomInput
        },
        props: {
            auto: {
                type: Boolean,
                default: false
            },
            defaultToggle: {
                type: Boolean,
                default: false
            },
            paywall: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                loading: false,
                loaded: false,
                loadingEmailValidation: [],
                abortController: null,
                search: '',
                loadingSearch: false,
                resettingSearch: false
            }
        },
        computed: {
            hide() {
                return (!this.people && !this.search && !this.resettingSearch) || (!this.loading && this.loaded && this.people.length < 1 && !this.search && !this.resettingSearch);
            },
            people() {
                return useProspectStore().people;
            },
            paywallActive() {
                return this.paywall && useAccountStore().paywall;
            }
        },
        methods: {
            async fetchPeople() {
                if (this.abortController) {
                    this.abortController.abort();
                }

                this.abortController = new AbortController();

                if(this.loaded) {
                    this.loadingSearch = true;
                } else {
                    this.loading = true;
                }
                
                await useProspectStore().fetchPeople(this.search, this.abortController.signal);

                if(this.loaded) {
                    this.loadingSearch = false;
                    this.resettingSearch = false;
                } else {
                    this.loading = false;
                }

                if(this.loaded) {
                    // Do nothing on search.
                } else if(this.people.length > 0) {
                    useToastStore().addToast(i18n.global.t(`toasts.success.prospect_people`), 'success');
                } else {
                    useToastStore().addToast(i18n.global.t(`toasts.error.prospect_people`), 'error'); 
                }

                this.loaded = true;
            },
            async validateEmail(emailItem, person) {
                if(!this.showEmailVerification(emailItem) && !this.loadingEmailValidation.includes(emailItem.email)) { return; }
                this.loadingEmailValidation.push(emailItem.email);

                try {
                    const response = await fetchUrl(emailItem.api);
                    if (response?.data?.verified === 1) {
                        person.email = emailItem.email;
                        person.emailVerified = true;
                    } else if (response?.data?.verified === 0) {
                        emailItem.confidence = null;
                        emailItem.status = 'invalid';
                    } else {
                        useToastStore().addToast(i18n.global.t('toasts.error.email_validation_failed'), 'error');
                    }
                } catch {
                    useToastStore().addToast(i18n.global.t('toasts.error.email_validation_failed'), 'error');
                } finally {
                    this.loadingEmailValidation = this.loadingEmailValidation.filter(e => e !== emailItem.email);
                }
            },
            addContactPopup(person) {
                usePopupStore().showPopup('form', {
                    title: 'popups.create_contact.title',
                    description: 'popups.create_contact.description',
                    formComponent: markRaw(AddContactForm),
                    formProps: {
                        person: person
                    },
                    onFormSuccess: () => {
                        useToastStore().addToast(i18n.global.t('toasts.success.contact_created'), 'success');
                    }
                });
            },
            showEmailVerification(emailItem) {
                return !this.paywallActive && emailItem.api && (!emailItem.checked_ago || emailItem.checked_ago > 1);
            },
            onChangedDebounced(value) {
                if(value != '') {
                    this.search = value;
                    this.updateDebounced();
                } else {
                    this.resetSearch();
                }
            },
            updateDebounced: debounce(function() {
                this.fetchPeople();
            }, 200),
            resetSearch() {
                this.resettingSearch = true;
                this.search = '';
                this.fetchPeople();
            },
            async handleLoadingStart() {
                if (!this.loaded) {
                    await this.fetchPeople();

                    if(!this.hide && this.$refs.WidgetSection) {
                        this.$refs.WidgetSection.toggleSection();
                    }
                }
            },
        },
        mounted() {
            if(!this.loaded && (this.auto || this.defaultToggle)) {
                this.fetchPeople();
            }
        },
        unmounted() {
            if (this.abortController) {
                this.abortController.abort();
            }
        }
    }
</script>