<template>
    <div class="nav-type-3-spacer"></div>
    <div class="container-fluid nav-type-3" :class="{ 'searching': isSearching }">
        <div class="container">
            <div class="nav-left">
                <router-link :to="logoLink">
                    <div class="nav-logo logo-bd-pos"></div>
                </router-link>
            </div>
            <div class="nav-right">
                <SearchBasic
                    @search-focus="onSearchFocus"
                    @search-blur="onSearchBlur"
                />
                <LanguageSwitch />
            </div>
        </div>
    </div>
</template>

<script>
    import { useUserStore } from '@/stores/user';
    import { useProspectStore } from '@/stores/prospect';
    import LanguageSwitch from '@/components/elements/misc/LanguageSwitch';
    import SearchBasic from '@/components/elements/search/SearchBasic';

    export default {
        name: 'TopNavSearch',
        components: {
            LanguageSwitch,
            SearchBasic
        },
        data() {
            return {
                isSearching: false,
            };
        },
        methods: {
            onSearchFocus() {
                this.isSearching = true;
            },
            onSearchBlur() {
                this.isSearching = false;
            },
        },
        computed: {
            isAuthenticated() {
                return useUserStore().isAuthenticated;
            },
            logoLink() {
                return this.isAuthenticated
                    ? { name: 'prospects' }
                    : { name: 'home' };
            },
            activeProspect() {
                return useProspectStore().prospect?.name ? useProspectStore().prospect.name : '';
            }
        },
    }
</script>
