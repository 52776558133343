<template>
    <OptionsSuspectPanel :count="activeOptionsCount">
        <template #options-default>
            <div class="logo">
                <div class="logo-pos"></div>
            </div>
            <div class="form-type-2">
                <OptionsSearch v-model="options.search" @update:modelValue="onChanged" />
            </div>
        </template>

        <template #options-counter v-if="queryTotal && total !== 0 && !hasDefaultOptions && !loading && !optionsChanged">
            <div class="counter">
                <div class="orange" v-if="queryTotal > 10000">{{ $t('components.suspect_options.results.high', { count: formatNumber(queryTotal) }) }}</div>
                <div class="green" v-else-if="queryTotal > 100">{{ $t('components.suspect_options.results.mid', { count: formatNumber(queryTotal) }) }}</div>
                <div class="orange" v-else>{{ $t('components.suspect_options.results.low', { count: formatNumber(queryTotal) }) }}</div>
            </div>
        </template>

        <template #options-no-results v-if="total === 0 && !hasDefaultOptions && !loading && !optionsChanged">
            <div class="no-results">
                <div>{{ $t('components.suspect_options.results.no_results') }}</div>
            </div>
        </template>

        <template #options-full>
            <div class="form-type-2">
                <OptionsEmployees v-model="options.employees" @update:modelValue="onChanged" />
                <OptionsRevenue v-model="options.revenue" @update:modelValue="onChanged" />
                <OptionsFounded v-model="options.founded" @update:modelValue="onChanged" />
                <OptionsLocation v-model="options.location" @update:modelValue="onChanged" />
                <OptionsApps v-model="options.apps" @update:modelValue="onChanged" />
                <OptionsSocials v-model="options.socials" @update:modelValue="onChanged" />
                <OptionsOrgType v-model="options.orgtype" @update:modelValue="onChanged" />
                <OptionsReviews v-model="options.reviews" @update:modelValue="onChanged" />
            </div>
        </template>
    </OptionsSuspectPanel>
</template>

<script>
    import { mapState, mapActions } from 'pinia';
    import { useSuspectStore } from '@/stores/suspect';
    import OptionsSuspectPanel from '@/components/panels/optionsSuspect/OptionsSuspectPanel';
    import OptionsSearch from '@/components/elements/suspect/OptionsSearch';
    import OptionsSocials from '@/components/elements/suspect/OptionsSocials';
    import OptionsLocation from '@/components/elements/suspect/OptionsLocation';
    import OptionsApps from '@/components/elements/suspect/OptionsApps';
    import OptionsOrgType from '@/components/elements/suspect/OptionsOrgType';
    import OptionsEmployees from '@/components/elements/suspect/OptionsEmployees';
    import OptionsRevenue from '@/components/elements/suspect/OptionsRevenue';
    import OptionsReviews from '@/components/elements/suspect/OptionsReviews';
    import OptionsFounded from '@/components/elements/suspect/OptionsFounded';
    import { formatNumber } from '@/utils/formatting';

    export default {
        name: "SuspectOptions",
        components: {
            OptionsSuspectPanel,
            OptionsSearch,
            OptionsSocials,
            OptionsLocation,
            OptionsApps,
            OptionsOrgType,
            OptionsEmployees,
            OptionsFounded,
            OptionsRevenue,
            OptionsReviews
        },
        data() {
            return {
                activeStatuses: {}
            };
        },
        computed: {
            ...mapState(useSuspectStore, ['options', 'total', 'queryTotal', 'optionsChanged', 'hasDefaultOptions', 'loading']),
            activeOptionsCount() {
                let count = 0;
                if(!!this.options.location.city || !!this.options.location.radius || this.options.location.province.length > 0) {
                    count++;
                }
                if(this.options.socials.length > 0) {
                    count++;
                }
                if(this.options.orgtype.length > 0) {
                    count++;
                }
                if(this.options.apps.length > 0) {
                    count++;
                }
                if(this.options.founded !== '') {
                    count++;
                }
                if(this.options.employees !== '') {
                    count++;
                }
                if(this.options.revenue !== '') {
                    count++;
                }
                if(this.options.reviews !== '') {
                    count++;
                }
                return count;
            }
        },
        methods: {
            formatNumber,
            ...mapActions(useSuspectStore, ['updateOptions', 'resetOptions']),
            onChanged() {
                this.updateOptions(this.options);
            }
        }
    }
</script>