<template>
    <div class="invoice-details-type-1">
        <div class="left">
            <div class="icon-card-pos"></div>
        </div>
        <div class="right">
            <div class="name">{{account.name}}</div>

            <div class="address">
                <div class="value">
                    <div class="address-line-1" v-if="account.address">{{account.address}}</div>
                    <div class="address-line-2" v-if="account.postal_code || account.city">{{account.postal_code}}{{account.city ? ' ' + account.city : ''}}</div>
                    <div class="address-line-3" v-if="(account.country  && countryOptions[account.country]) || true">{{$t(countryOptions[account.country] || countryOptions['nl'])}}</div>
                </div>
            </div>
            <div class="vat" v-if="account.invoice_vat">
                <div class="h6">{{$t('common.vat')}}</div>
                <div class="value">{{account.invoice_vat}}</div>
            </div>
            <div class="po-number" v-if="account.invoice_po">
                <div class="h6">{{$t('common.po_number')}}</div>
                <div class="value">{{account.invoice_po}}</div>
            </div>
        </div>
    </div>
</template>

<script>
    import { useAccountStore } from '@/stores/account';

    export default {
        name: 'InvoiceDetails',
        data() {
            return {
                countryOptions: {
                    'nl': "fields.invoice_country.option_nl",
                    'be': "fields.invoice_country.option_be",
                    'de': "fields.invoice_country.option_de",
                    'fr': "fields.invoice_country.option_fr",
                    'lu': "fields.invoice_country.option_lu",

                }
            }
        },
        computed: {
            account() {
                return useAccountStore().account;
            }
        }
    }
</script>