<template>
    <div class="row user-item" :class="[user.editable && !user.self ? 'hasActions' : '']">
        <div class="item-image">
            <GravatarImage 
                :email="user.email ? user.email : null"
                :name="user.name ? user.name : user.email ? user.email : null"
            />
        </div>

        <div class="content">
            <div class="name">{{ user.name || user.email || '-' }}</div>
            <p class="description">
                {{ user.name ? $t('fields.user_role.options.' + user.role) + ' - ' + user.email : $t('fields.user_role.options.' + user.role) }}
            </p>
        </div>

        <div class="actions" v-if="user.editable && !user.self">
            <div class="btn-1 btn-s-icon" @click="editUserPopup">
                <div class="icon-edit-neg icon-xxs"></div>
            </div>
            <div class="btn-6 btn-s-icon" @click="deleteUserPopup">
                <div class="icon-trash-neg icon-xxs"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import { markRaw } from 'vue';
    import i18n from '@/plugins/i18n';
    import { useUsersStore } from '@/stores/users';
    import { usePopupStore } from '@/stores/popup';
    import { useToastStore } from '@/stores/toast';
    import GravatarImage from '@/components/elements/misc/GravatarImage';
    import UserEditForm from '@/components/forms/UserEditForm';

    export default {
        name: 'PartnerUsersListItem',
        components: {
            GravatarImage
        },
        props: {
            user: {
                type: Object,
                required: true,
            },
        },
        computed: {
            usersStore() {
                return useUsersStore();
            },
            popupStore() {
                return usePopupStore();
            },
            toastStore() {
                return useToastStore();
            }
        },
        methods: {
            editUserPopup() {
                this.popupStore.showPopup('form', {
                    title: 'popups.edit_partner_user.title',
                    description: 'popups.edit_partner_user.description',
                    formComponent: markRaw(UserEditForm),
                    formProps: {
                        user: this.user
                    },
                    translationPlaceholders: {
                        description: {
                            userName: this.user.name ? this.user.name : this.user.email
                        }
                    },
                    onFormSuccess: () => {
                        this.toastStore.addToast(i18n.global.t('toasts.success.user_updated', { nameOrEmail: this.user.name ? this.user.name : this.user.email }), 'success');
                    },
                });
            },
            deleteUserPopup() {
                this.popupStore.showPopup('confirm', {
                    title: 'popups.confirm_partner_user_delete.title',
                    description: 'popups.confirm_partner_user_delete.description',
                    btnConfirm: "popups.confirm_partner_user_delete.button_confirm",
                    btnCancel: "common.cancel_1",
                    danger: true,
                    confirmFunction: this.deleteUser,
                    translationPlaceholders: {
                        description: {
                            userName: this.user.name ? this.user.name : this.user.email
                        }
                    }
                });
            },
            async deleteUser() {
                try {
                    const response = await this.usersStore.deleteUser(this.user);

                    if(response) {
                        this.toastStore.addToast(i18n.global.t('toasts.success.user_deleted', {email: this.user.email}), 'success');
                    } else {
                        this.toastStore.addToast(i18n.global.t('toasts.error.user_deletion_failed', {email: this.user.email}), 'error');
                    } 
                } catch {
                    this.toastStore.addToast(i18n.global.t('toasts.error.user_deletion_failed', {email: this.user.email}), 'error');   
                }
            }
        },
    };
</script>