<template>
    <AccountViewBase :account="account" :loading="loading" :error="error">
        <div class="container-fluid content-type-22">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-10 col-md-offset-1 col-xl-8 col-xl-offset-2">
                        <div class="page-title">
                            <h1>{{ $t(`views.subscription_change.title`) }}</h1>
                            <p>{{ $t(`views.subscription_change.description`) }}</p>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-sm-10 col-sm-offset-1 col-lg-12 col-lg-offset-0 col-xl-10 col-xl-offset-1">
                        <div class="content">
                            <SubscriptionForm btnLabel="views.subscription_change.buttons.change" v-if="!loading" @saved="changeSubscriptionPopup" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </AccountViewBase>
</template>

<script>
    import router from '@/router';
    import AccountViewBase from '@/views/account/AccountViewBase';
    import i18nViewMixin from '@/mixins/i18nViewMixin';
    import accountMixin from '@/mixins/accountMixin';
    import { useOrderStore } from '@/stores/order';
    import { useAccountStore } from '@/stores/account';
    import SubscriptionForm from '@/components/forms/SubscriptionForm';
    import { mapState } from 'pinia';
    import { usePopupStore } from '@/stores/popup';
    import { timestampToDisplayDate } from '@/utils/dates';
    import i18n from "@/plugins/i18n";
    import { useToastStore } from '@/stores/toast';

    export default {
        name: 'SubscriptionsView',
        components: {
            AccountViewBase,
            SubscriptionForm
        },
        mixins: [i18nViewMixin, accountMixin],
        data() {
            return {
                seo_title: 'views.change_subscription.seo_title'
            };
        },
        computed: {
            ...mapState(useAccountStore, ['subscriptions']),
            subscription() {
                return this.subscriptions?.length > 0 ? this.subscriptions[0] : false;
            },
            orderStore() {
                return useOrderStore();
            },
            toastStore() {
                return useToastStore();
            }
        },
        methods: {
            changeSubscriptionPopup(product) {
                const renewalDate = this.subscription?.paid_to_time ? timestampToDisplayDate(this.subscription.paid_to_time, false) : false;
                const currentPrice = this.subscription.price || false;

                if (currentPrice && currentPrice < product.price) {
                    usePopupStore().showPopup('confirm', {
                        title: 'popups.confirm_subscription_upgrade.title',
                        description: 'popups.confirm_subscription_upgrade.description',
                        btnConfirm: "popups.confirm_subscription_upgrade.button_confirm",
                        btnCancel: "popups.confirm_subscription_upgrade.button_cancel",
                        confirmFunction: () => this.updateSubscription(product.product),
                        danger: true,
                        translationPlaceholders: {
                            description: {
                                price: product.price,
                                subscription: i18n.global.t(product.name)
                            }
                        }
                    });
                } else if (currentPrice && renewalDate && currentPrice > product.price) {
                    usePopupStore().showPopup('confirm', {
                        title: 'popups.confirm_subscription_downgrade.title',
                        description: 'popups.confirm_subscription_downgrade.description',
                        btnConfirm: "popups.confirm_subscription_downgrade.button_confirm",
                        btnCancel: "popups.confirm_subscription_downgrade.button_cancel",
                        confirmFunction: () => this.updateSubscription(product.product),
                        danger: true,
                        translationPlaceholders: {
                            description: {
                                price: product.price,
                                subscription: i18n.global.t(product.name),
                                renewalDate: renewalDate || '[?]',
                            }
                        }
                    });
                } else {
                    usePopupStore().showPopup('confirm', {
                        title: 'popups.confirm_subscription_update.title',
                        description: 'popups.confirm_subscription_update.description',
                        btnConfirm: "popups.confirm_subscription_update.button_confirm",
                        btnCancel: "popups.confirm_subscription_update.button_cancel",
                        confirmFunction: () => this.updateSubscription(product.product),
                        danger: true,
                        translationPlaceholders: {
                            description: {
                                price: product.price,
                                subscription: i18n.global.t(product.name),
                            }
                        }
                    });
                }
            },
            async updateSubscription(product) {
                this.loading = true;
                const accountStore = useAccountStore();
                const response = await accountStore.updateSubscription(this.subscription.order_id, product);

                if(response) {
                    router.push({ name: 'subscriptions' });
                    this.toastStore.addToast(i18n.global.t('toasts.success.subscription_updated'), 'success');
                } else {
                    this.toastStore.addToast(i18n.global.t('toasts.error.subscription_not_updated'), 'error');
                }

                this.loading = false;
            }
        },
        async mounted() {
            this.orderStore.saveOrderSettings(true);
            this.orderStore.resetOrder();
            await useAccountStore().getSubscriptions();

            if(this.subscription?.coupon_discount > 0) {
                this.orderStore.setCoupon({ discount: this.subscription.coupon_discount });
            }
        },
        unmounted() {
            this.orderStore.saveOrderSettings(true);
            this.orderStore.resetOrder();
        },
    }
</script>
