import { defineStore } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useAccountStore } from '@/stores/account';

export const useOrderStore = defineStore('order', {
    state: () => {
        const accountStore = useAccountStore();
        const accountId = accountStore?.account?.account_id ? accountStore.account.account_id : null;
        const userStore = useUserStore();
        const orderSettings = userStore.getOrderSettings(accountId || null);

        return {
            order: orderSettings.order || null,
            step: orderSettings.step || 1,
            subscriptionType: orderSettings.subscriptionType || accountStore.productType || null,
            coupon: orderSettings.coupon || {},
            invoiceDetailsModified: false,
            defaultSteps: [
                {
                    key: "subscription",
                    name: "Abonnement",
                },
                {
                    key: "invoice_details",
                    name: "Facturatie",
                },
                {
                    key: "summary",
                    name: "Betaling",
                }
            ]
        }
    },
    getters: {
        steps() {
            return this.defaultSteps.map((step, index) => ({
                ...step,
                current: this.step === index + 1 ? true : false,
                clickable: this.validateStepClickable(step.key),
                completed: this.validateStep(step.key),
                order: this.order
            }));
        },
        currentStep() {
            return this.steps[this.step - 1] || false;
        },
        newSubscriptionSelected() {
            const accountStore = useAccountStore();
            const subscriptions = accountStore.subscriptions;

            if(subscriptions?.length > 0) {
                return (subscriptions[0].product_main !== this.subscriptionType && subscriptions[0].status !== 'canceled') ||
                subscriptions[0].status === 'canceled';
            } else {
                return true;
            }
        },
        activeDiscount() {
            return this.coupon?.discount > 0 ? this.coupon.discount : 0;
        }
    },
    actions: {
        resetOrder() {
            this.$reset();
        },
        saveOrderSettings(deleteSettings = false) {
            const userStore = useUserStore();
            const accountStore = useAccountStore();

            userStore.saveOrderSettings(
                accountStore.account.account_id,
                deleteSettings ? null : {
                    subscription: this.subscription,
                    subscriptionType: this.subscriptionType,
                    coupon: this.coupon,
                    step: this.step,
                    order: this.order
                }
            );
        },
        findStep(key) {
            const index = this.steps.findIndex(step => step.key === key);
            return index + 1;
        },
        navigateToStep(step) {
            if(typeof step === 'number') {
                this.step = step;
            } else if (typeof step === 'string' && step !== '') {
                this.step = this.findStep(step) || this.step;
            }
            this.invoiceDetailsModified = false;
        },
        validateStepClickable(key) {
            const accountStore = useAccountStore();

            switch (key) {
                case 'subscription' :
                    return true;
                case 'invoice_details':
                    return this.newSubscriptionSelected;
                case 'summary':
                    return this.newSubscriptionSelected && accountStore.hasInvoiceAddress;
                default:
                    return false;
            }
        },
        validateStep(key) {
            const accountStore = useAccountStore();

            switch (key) {
                case 'subscription' :
                    return this.newSubscriptionSelected;
                case 'invoice_details':
                    return this.newSubscriptionSelected && accountStore.hasInvoiceAddress;
                case 'summary':
                    return false;
                default:
                    return false;
            }
        },
        setCoupon(coupon) {
            if(parseFloat(coupon.discount) > 0) {
                this.coupon = coupon;
            } else {
                this.coupon = {};
            }
        },
        setSubscription(subscriptionType) {
            this.updateSubscription(subscriptionType);
            this.navigateToStep(2)
        },
        updateSubscription(subscriptionType) {
            this.subscriptionType = subscriptionType;
        },
        setInvoiceDetails() {
            this.invoiceDetailsModified = false;
            this.navigateToStep(3);
        },
        updateInvoiceDetailsModified(val) {
            this.invoiceDetailsModified = val;
        },
        generateProduct() {
            return this.subscriptionType ? `pp_${this.subscriptionType}_v1` : 'pp_free_v1';
        },
        async startPayment() {
            try {
                const response = await useAccountStore().createSubscription({
                    product: this.generateProduct(),
                    ...(this.coupon?.coupon && { coupon: this.coupon.coupon })
                });
                this.order = response.data;
                return true;
            } catch {
                return false;
            } finally {
                this.saveOrderSettings();
                this.redirectPayment();
            }
        },
        async redirectPayment() {
            if(this.order.order_id && this.order.checkout_url) {
                window.location.href = this.order.checkout_url;
            }
        }
    },
});
