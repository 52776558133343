<template>
    <button @click="createUserPopup" class="btn-s-icon btn-1">
        <div class="icon-plus-neg"></div>
        <span>{{$t('components.create_partner_user.button_create')}}</span>
    </button>
</template>

<script>
    import i18n from "@/plugins/i18n";
    import { usePopupStore } from '@/stores/popup';
    import { useToastStore } from '@/stores/toast';
    import UserCreateForm from '@/components/forms/UserCreateForm';
    import { markRaw } from 'vue';
    import { scrollToTop } from '@/utils/scroll';

    export default {
        name: "PartnerUserCreate",
        computed: {
            popupStore() {
                return usePopupStore();
            },
            toastStore() {
                return useToastStore();
            }
        },
        methods: {
            createUserPopup() {
                this.popupStore.showPopup('form', {
                    title: 'popups.create_partner_user.title',
                    description: 'popups.create_partner_user.description',
                    formComponent: markRaw(UserCreateForm),
                    onFormSuccess: () => {
                        this.toastStore.addToast(i18n.global.t('toasts.success.user_created'), 'success');
                        scrollToTop();
                    }
                });
            },
        },
    }
</script>