<template>
    <div class="toast-type-1" :class="[this.cssHorizontal, this.cssVertical]" v-if="toastStore.toasts?.length > 0">
        <div 
        v-for="toast in toastStore.toasts"
        :key="toast.id" 
        :class="['toast', toast.type]"
        @click="removeToast(toast.id)">
            <div class="icon">
                <div v-if="toast.type === 'success'" class="icon-check-pos"></div>
                <div v-else-if="toast.type === 'error'" class="icon-close-pos"></div>
                <div v-else-if="toast.type === 'neutral'" class="icon-information-pos"></div>
                <div v-else-if="toast.type" :class="`icon-${ toast.type }-pos`"></div>
                <div v-else class="icon-check-pos"></div>
            </div>
            <div class="message">
                {{ toast.message }}
            </div>
            <div class="close">
                <div class="icon-close-pos"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import { useToastStore } from '@/stores/toast';
    import { nextTick } from 'vue';

    export default {
        name: 'ToastNotifications',
        data() {
            return {
                cssHorizontal: '',
                cssVertical: ''
            }
        },
        computed: {
            toastStore() {
                return useToastStore();
            }
        },
        methods: {
            removeToast(id) {
                this.toastStore.removeToast(id);
            },
            setCssVertical() {
                const options_1_button = document.querySelector('div.options-type-1-button');
                const content_5 = document.querySelector('div.content-type-5');
                const content_23 = document.querySelector('div.content-type-23');
                const pagination_1 = document.querySelector('div.pagination-type-1');
                
                if(options_1_button) {
                    this.cssVertical = 'options-1-button';
                } else if (content_5) {
                    this.cssVertical = 'content-5';
                } else if (content_23) {
                    this.cssVertical = 'content-23';
                } else if (pagination_1) {
                    this.cssVertical = 'pagination-1';
                } else {
                    this.cssVertical = '';
                }
            },
            setCssHorizontal() {
                const account = document.querySelector('div.account-view');
                if (account) {
                    this.cssHorizontal = 'sideNav';
                } else {
                    this.cssHorizontal = '';
                }
            }
        },
        watch: {
            $route() {
                this.toastStore.removeAllToasts();
                this.setCssHorizontal();
            },
            'toastStore.toasts'() {
                setTimeout(() => {
                    this.setCssVertical();
                }, 300);
                nextTick(() => {
                    this.setCssVertical();
                });
            }
        },
        mounted() {
            this.setCssHorizontal();
        }
    };
</script>