<template>
    <div :class="['row', 'section', cssClass]">
        <div class="col-12 col-md-10 col-md-offset-1 col-xl-3 col-xl-offset-1">
            <div class="title">
                <slot name="title"></slot>
            </div>
        </div>
        <div class="col-12 col-md-10 col-md-offset-1 col-xl-6 col-xl-offset-0">
            <div class="content" v-if="!loading">
                <slot name="content"></slot>
            </div>
            <LoadingSection v-else />
        </div>
    </div>
</template>

<script>
    import LoadingSection from '@/components/sections/LoadingSection';

    export default {
            name: 'AccountSettingsSection',
            components: {
                LoadingSection
            },
            props: {
                cssClass: {
                    type: String,
                    required: false
                },
                loading: {
                    type: Boolean,
                    default: false
                }
            }
    }
</script>