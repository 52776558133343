import router from '@/router';
import { defineStore } from 'pinia';
import { fetchAccount, updateAccount, fetchAccountTags, fetchAccountApiKey, deleteAccountApiKey, fetchAccountInvoices, createSubscription, fetchSubscriptions, updateSubscription, deleteSubscription, validateAccountTracker } from "@/services/ppApi";
import { useSuspectStore } from '@/stores/suspect';
import { useProspectStore } from '@/stores/prospect';
import { useProspectsStore } from '@/stores/prospects';
import { useUserStore } from '@/stores/user';
import { useUsersStore } from '@/stores/users';
import { useOrderStore } from '@/stores/order';

export const useAccountStore = defineStore('account', {
    state: () => {
        const defaultTags = [
            { value: 'Suspect', text: 'Suspect', count: 1 },
            { value: 'Prospect', text: 'Prospect', count: 1 },
            { value: 'Opportunity', text: 'Opportunity', count: 1 },
            { value: 'Customer', text: 'Customer', count: 1 },
            { value: 'Partner', text: 'Partner', count: 1 },
            { value: 'Rival', text: 'Rival', count: 1 }
        ];

        return {
            account: JSON.parse(localStorage.getItem('pp_account')) || null,
            error: null,
            loading: false,
            defaultTags: defaultTags,
            tags: defaultTags,
            subscriptions: [],
            paywall: false,
            lastFetched: 0
        }
    },
    getters: {
        hasDefaultTags: (state) => JSON.stringify(state.tags) === JSON.stringify(state.defaultTags),
        hasAccountId: (state) => state.account && typeof state.account.account_id === 'string' && state.account.account_id.trim().length > 0,
        hasFullAccount: (state) => state.account && state.account.account_id && state.account.name && state.account.url && state.account.role,
        hasInvoiceAddress: (state) => state.account && (state.account.invoice_name || state.account.name) && state.account.invoice_address && state.account.invoice_city && state.account.invoice_postcode && state.account.invoice_country_code,
        isActive: (state) => state.account?.status > 0 || false,
        hasSubscriptions: (state) => state.subscriptions?.length > 0,
        product: (state) => state.account?.product || null,
        productType: (state) => state.account?.product_main || 'free',
        paid_to_time: (state) => state.account?.paid_to_time || 0,
        accountRole: (state) => state.account?.role || 1000,
        isAdmin: (state) => state.account?.role < 15 || false
    },
    actions: {
        setAccount(account) {
            if (account) {
                if (account.account_id && account.account_id === this.account?.account_id) {
                    this.account = { ...this.account, ...account };
                } else {
                    this.account = account;
                }
                localStorage.setItem('pp_account', JSON.stringify(this.account));
                this.setPaywall();
            } else {
                this.account = null;
                this.error = null;
                this.loading = false;
                localStorage.removeItem('pp_account');
            }
        },
        resetAccount() {
            this.tags = this.defaultTags; // Somehow this.$reset below doesn't reset this automatically..
            this.subscriptions = [];
            this.setAccount(null);
            this.$reset;

            // Clear stores related to the account
            useUsersStore().$reset();
            useSuspectStore().$reset();
            useProspectStore().$reset();
            useProspectsStore().$reset();
            useOrderStore().$reset();
        },
        resetState() {
            this.error = null;
            this.loading = false;
        },
        changeAccount(account_id) {
            this.resetAccount();
            this.setAccount({ account_id })
        },
        setApiKeyStatus(status) {
            this.account.api_key_exists = status;
            this.setAccount(this.account);
        },
        setAccountTags(tags) {
            tags = tags.filter(tag => tag.count && tag.count > 0);
            if(tags.length > 0) {
                this.tags = tags;
            } else {
                this.tags = this.defaultTags;
            }
        },
        setPaywall() {
            const userStore = useUserStore();
            this.paywall = (this.productType === 'free' || !this.isActive) && !userStore.isPartnerAdmin;
        },
        async fetchAccount() {
            if (this.hasAccountId) {
                this.loading = true;
                try {
                    const response = await fetchAccount(this.account.account_id);

                    if(response.data.found && response.data.found === 1) {
                        const account = response.data.accounts[0];
                        this.setAccount(account);
                        this.lastFetched = Date.now();
                    } else {
                        router.push({ name: 'accounts'} );
                    }
                } catch (error) {
                    this.error = error;
                } finally {
                    this.loading = false;
                }
            } else {
                return false;
            }
        },
        async getAccount(forceFetch = false) {
            if (!this.hasAccountId) {
                router.push({ name: 'accounts'} );
            }

            const now = Date.now();
            const tenMinutes = 10 * 60 * 1000;
            if (!this.hasFullAccount || forceFetch || (now - this.lastFetched) > tenMinutes) {
                await this.fetchAccount();
            }

            this.setPaywall();
            return this.hasFullAccount ? this.account : false;
        },
        async updateAccount(updatedAccount) {
            if (this.hasAccountId) {
                updatedAccount = { account_id: this.account.account_id, ...updatedAccount };
            }

            try {
                const response = await updateAccount(updatedAccount);
                this.setAccount(response.data.accounts[0]);
                return true;
            } catch (error) {
                return false;
            }
        },
        async fetchAccountTags() {
            if (this.hasAccountId) {
                try {
                    const response = await fetchAccountTags(this.account.account_id);

                    if(response.data.found && response.data.found > 0) {
                        var tags = response.data.tags ?
                            Object.keys(response.data.tags).map(key => ({
                                value: key,
                                text: key,
                                count: response.data.tags[key]
                            })) : this.tags;
                        this.setAccountTags(tags);
                    } else {
                        return false;
                    }
                } catch {
                    return false;
                }
            } else {
                return false;
            }
        },
        async getAccountTags() {
            if(this.hasDefaultTags || (this.tags && this.tags.length < 1)) { // Combine this with C(R)UD-labels (i.e. reload tags immediately after)
                await this.fetchAccountTags();
            }
            return this.tags.length > 0 ? this.tags : this.defaultTags;
        },
        async validateTracker() {
            if (this.hasAccountId) {
                try {
                    const response = await validateAccountTracker(this.account.account_id);
                    return !!response.data?.tracker_installed;
                } catch (error) {
                    return false;
                }
            } else {
                return false;
            }
        },
        async fetchAccountApiKey() {
            if (this.hasAccountId) {
                try {
                    const response = await fetchAccountApiKey(this.account.account_id);
                    return response.data.api_key;
                } catch (error) {
                    return false;
                }
            } else {
                return false;
            }
        },
        async deleteAccountApiKey() {
            if (this.hasAccountId) {
                try {
                    return await deleteAccountApiKey(this.account.account_id);
                } catch (error) {
                    return false;
                }
            } else {
                return false;
            }
        },
        async createSubscription(order) {
            if (this.hasAccountId) {
                try {
                    return await createSubscription({ ...order, account_id: this.account.account_id});
                } catch (error) {
                    return false;
                }
            } else {
                return false;
            }
        },
        async updateSubscription(order_id, product) {
            if (this.hasAccountId) {
                try {
                    const response = await updateSubscription({ order_id, product, account_id: this.account.account_id});

                    if(response) {
                        await this.getAccount(true); // Force a fetch
                        await this.getSubscriptions(true); // Force a fetch
                        return true;
                    }
                } catch (error) {
                    return false;
                }
            } else {
                return false;
            }
        },
        async deleteSubscription(order_id) {
            if (this.hasAccountId) {
                try {
                    const response = await deleteSubscription({ order_id, account_id: this.account.account_id});

                    if(response) {
                        await this.getAccount(true); // Force a fetch
                        await this.getSubscriptions(true); // Force a fetch
                        return true;
                    }
                } catch (error) {
                    return false;
                }
            } else {
                return false;
            }
        },
        async getSubscriptions(forceFetch = false) {
            if(forceFetch || (this.subscriptions && this.subscriptions.length < 1)) {
                const response = await this.fetchSubscriptions();

                if(!response) {
                    await this.fetchSubscriptions('canceled');
                }
            }
            return this.subscriptions;
        },
        async getSubscription(forceFetch = false) {
            const subscriptions = await this.getSubscriptions(forceFetch);
            return subscriptions?.length > 0 ? subscriptions[0] : false;
        },
        async fetchSubscriptions(status = 'new,paid,active') {
            if (this.hasAccountId) {
                try {
                    const response = await fetchSubscriptions(this.account.account_id, status);
                    if(response.data.found > 0) {
                        return this.processSubscriptions(response.data.subscriptions);
                    } else {
                        return false;
                    }
                } catch (error) {
                    return false;
                }
            } else {
                return false;
            }
        },
        processSubscriptions(subscriptions) {
            const cleanSubscriptions = this.filterSubscriptions(subscriptions);
            if(cleanSubscriptions?.length > 0) {
                this.subscriptions = [cleanSubscriptions[0]];
                return true;
            } else {
                this.subscriptions = [];
                return false;
            }
        },
        filterSubscriptions(subscriptions) {
            const now = Date.now();
            return subscriptions.filter((subscription) => {
                const { status, paid_to_time } = subscription;
                const isDesiredStatus = ["new", "active", "paid"].includes(status);
                const isCancelledWithFutureTime = status === "canceled" && new Date(paid_to_time * 1000) > now;
                return isDesiredStatus || isCancelledWithFutureTime;
            });
        },
        async fetchInvoices() {
            if (this.hasAccountId) {
                try {
                    const response = await fetchAccountInvoices(this.account.account_id);
                    if(response.data.found > 0) {
                        return response.data.invoices;
                    } else {
                        return false;
                    }
                } catch (error) {
                    return false;
                }
            } else {
                return false;
            }
        },
    }
});