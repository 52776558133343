<template>
    <div class="form-type-0">
        <form @submit.prevent="editAccount" @input="onInputChange" ref="form" novalidate>
            <CustomInput
                v-model="updatedAccount.name"
                inputKey="name"
                type="text"
                ref="name"
                autocomplete="organization"
                required
            />

            <CustomInput
                v-model="updatedAccount.url"
                inputKey="url"
                type="url"
                ref="url"
                autocomplete="url"
                required
            />

            <CustomSelect
                v-if="userStore.isPartnerGod"
                v-model="updatedAccount.status"
                inputKey="options_status"
                ref="status"
                :options="statusOptions"
            />

            <CustomSelect
                v-if="showProducts && userStore.isPartnerGod && account.product !== 'pp_trial_v1'"
                v-model="updatedAccount.product"
                inputKey="options_product"
                ref="product"
                :options="productOptions"
            />

            <CustomSelect
                v-if="showProducts && userStore.isPartnerGod && updatedAccount.product !== 'pp_free_v1'"
                v-model="updatedAccount.paid_to_time_option"
                inputKey="options_paid_to_time"
                ref="paid_to_time"
                :options="optionsPaidToTime"
            />
            <button type="submit" :disabled="loading">
                <span v-if="loading" class="loading-spinner-type-1"></span>
                <span v-else>{{ $t('forms.edit_account.button') }}</span>
            </button>
            <p v-if="formError" class="form-error">{{ $t('forms.edit_account.error') }}</p>
        </form>
    </div>
</template>

<script>
    import { useAccountsStore } from '@/stores/accounts';
    import { useUserStore } from '@/stores/user';
    import formMixin from '@/mixins/formMixin';
    import CustomInput from '@/components/elements/inputs/CustomInput';
    import CustomSelect from '@/components/elements/inputs/CustomSelect';
    import { addDaysToEpoch } from '@/utils/dates';

    export default {
        mixins: [formMixin],
        emits: ['form_submission_successful'],
        components: {
            CustomInput,
            CustomSelect
        },
        props: {
            account: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                updatedAccount: { 
                    ...this.account,
                    paid_to_time_option: 'default'    
                },
                productOptions: [
                    { value: "pp_free_v1", text: 'fields.options_product.options.pp_free_v1' },
                    { value: "pp_trial_v1", text: 'fields.options_product.options.pp_trial_v1' },
                ],
                optionsPaidToTime: [
                    { value: "default", text: 'fields.options_paid_to_time.options.default' },
                    { value: "0", text: 'fields.options_paid_to_time.options.0' },
                    { value: "3", text: 'fields.options_paid_to_time.options.3' },
                    { value: "7", text: 'fields.options_paid_to_time.options.7' },
                    { value: "14", text: 'fields.options_paid_to_time.options.14' },
                    { value: "30", text: 'fields.options_paid_to_time.options.30' }
                ],
                statusOptions: [
                    { value: 1, text: 'fields.options_status.options.1' },
                    { value: -1, text: 'fields.options_status.options.-1' },
                ],
            };
        },
        computed: {
            accountsStore() {
                return useAccountsStore();
            },
            userStore() {
                return useUserStore();
            },
            showProducts() {
                return this.account.product !== 'pp_frl_v1' && this.account.product !== 'pp_unl_v1';
            }
        },
        methods: {
            async editAccount() {
                const form = this.$refs.form;
                this.generateTimeToPaid();

                if(form.checkValidity()) {
                    this.loading = true;
                    const success = await this.accountsStore.editAccount(this.updatedAccount);
                    this.loading = false;
                    
                    if (success) {
                        form.reset();
                        this.formError = false;
                        this.$emit('form_submission_successful');
                    } else {
                        this.formError = true;
                    }
                } else {
                    this.validateAllFields();
                }
            },
            generateTimeToPaid() {
                if(this.updatedAccount.paid_to_time_option !== 'default') {
                    let currentEpoch = this.updatedAccount.paid_to_time && this.updatedAccount.paid_to_time > Date.now() ? this.updatedAccount.paid_to_time : Date.now();
                    switch (this.updatedAccount.paid_to_time_option) {
                        case "0" :
                            this.updatedAccount.paid_to_time = addDaysToEpoch(currentEpoch, -1);
                            return;
                        case "3" :
                            this.updatedAccount.paid_to_time = addDaysToEpoch(currentEpoch, 3);
                            return;
                        case "7" :
                            this.updatedAccount.paid_to_time = addDaysToEpoch(currentEpoch, 7);
                            return;
                        case "14" :
                            this.updatedAccount.paid_to_time = addDaysToEpoch(currentEpoch, 14);
                            return;
                        case "30" :
                            this.updatedAccount.paid_to_time = addDaysToEpoch(currentEpoch, 30);
                            return;
                        default :
                            return;
                    }
                }
            }
        }
    }
</script>