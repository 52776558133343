<template>
    <SettingsSection cssClass="account-details-settings">
        <template #title>
            <h2>{{ $t('views.account_subscriptions.invoice_details_title') }}</h2>
        </template>

        <template #content>
            <InvoiceDetailsForm :hideSaveButton="false" :hideConsent="true" />
        </template>
    </SettingsSection>
</template>

<script>
    import SettingsSection from '@/components/account/settings/Section';
    import InvoiceDetailsForm from '@/components/forms/InvoiceDetailsForm';
    
    export default {
        name: 'InvoiceSettings',
        components: {
            SettingsSection,
            InvoiceDetailsForm
        }
    }
</script>